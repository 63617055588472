import React, { useRef } from 'react';
import VideoWc from 'src/wcs/Video';

interface Props {
    width: number;
    height: number;
    url: string;
    poster?: string;
}

const Player: React.FC<Props> = ({ width, height, url, poster }: Props) => {
    // const videoRef = useRef<HTMLVideoElement>(null);
    //
    // useEffect(() => {
    //     videoRef.current?.load();
    // }, [url]);

    const ref = useRef<VideoWc>(null);

    if (!width || !height || !url) {
        return null;
    }

    // return <r-video width={width} height={height} loop src={url} />;
    return (
        <r-video
            ref={ref}
            width={width}
            height={height}
            autoplay={true}
            muted={true}
            loop={true}
            src={url}
            poster={poster}
        />
    );
};

export default Player;
