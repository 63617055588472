import { muteIcon, pauseIcon, playIcon, unmuteIcon } from 'src/wcs/Video/icons';
import VideoWc from 'src/wcs/Video/index';

const MULTI = 100;

export default class Controls {
    private __parent: VideoWc;
    __video: HTMLVideoElement;
    private __volume = 0.75;

    private __seekMouseMoveHandler?: (event: MouseEvent) => void;
    private __mouseDownMouseMoveHandler?: () => void;
    private __fullscreenChangeHandler?: () => void;
    private __overlayMouseMoveHandler?: (event: MouseEvent) => void;
    private __keydownHotkeyHandler?: (event: KeyboardEvent) => void;
    private __toggleVideoFullscreen?: () => void;
    private __toggleVideoPlay?: () => void;
    private __toggleVideoMute?: () => void;
    private __toggleVideoLoop?: () => void;

    formatTime(seconds: number) {
        const str = new Date(seconds * 1000).toISOString().slice(11, 11 + 8);
        return [str.slice(3, 3 + 2), str.slice(6, 6 + 2)];
    }

    getById(id: string) {
        return this.__parent.__root.getElementById(id);
    }

    initOverlay() {
        const overlay = this.getById('overlay') as HTMLDivElement;
        const container = this.getById('container') as HTMLDivElement;

        this.__video.addEventListener('pause', () => {
            overlay?.classList.add('video__overlay--visible');
        });

        this.__video.addEventListener('loadeddata', () => {
            container.classList.remove('video--loading');
        });

        let timeoutId: NodeJS.Timeout;

        this.__video.addEventListener('play', () => {
            timeoutId = setTimeout(() => {
                overlay?.classList.remove('video__overlay--visible');
            }, 1000 * 2);
        });

        this.__overlayMouseMoveHandler = ({ target }) => {
            if (!this.__video.paused) {
                clearTimeout(timeoutId);
                overlay?.classList.add('video__overlay--visible');
                timeoutId = setTimeout(() => {
                    if (!(target as HTMLElement)?.closest('.video__bottom')) {
                        overlay?.classList.remove('video__overlay--visible');
                    }
                }, 1000 * 2);
            }
        };

        window.addEventListener('mousemove', this.__overlayMouseMoveHandler);
    }

    initTime() {
        const elapsed = this.getById('elapsed') as HTMLTimeElement;
        const duration = this.getById('duration') as HTMLTimeElement;

        this.__video?.addEventListener('loadedmetadata', () => {
            const roundedTime = Math.round(this.__video.duration);
            const [minutes, seconds] = this.formatTime(roundedTime);
            duration.innerText = `${minutes}:${seconds}`;
            duration.setAttribute('datetime', `${minutes}m ${seconds}s`);
        });

        this.__video?.addEventListener('timeupdate', () => {
            const roundedTime = Math.round(this.__video.currentTime);
            const [minutes, seconds] = this.formatTime(roundedTime);
            elapsed.innerText = `${minutes}:${seconds}`;
            elapsed.setAttribute('datetime', `${minutes}m ${seconds}s`);
        });
    }

    initProgress() {
        const progress = this.getById('progress') as HTMLProgressElement;
        const seek = this.getById('seek') as HTMLInputElement;
        const tooltip = this.getById('tooltip') as HTMLDivElement;

        this.__video?.addEventListener('loadedmetadata', () => {
            const roundedTimeWithMulti = Math.round(this.__video.duration * MULTI);
            progress?.setAttribute('max', roundedTimeWithMulti.toString());
        });

        this.__video?.addEventListener('timeupdate', () => {
            const roundedTimeWithMulti = Math.round(this.__video.currentTime * MULTI);
            seek?.setAttribute('data-value', roundedTimeWithMulti.toString());
            progress.value = roundedTimeWithMulti;
        });

        this.__seekMouseMoveHandler = (event: MouseEvent) => {
            if (seek && this.__video.duration) {
                const { left: seekLeft, width: seekWidth } = seek.getBoundingClientRect();
                const { left: bodyLeft } = document.body.getBoundingClientRect();
                const min = Math.max(event.pageX - seekLeft - bodyLeft, 0);
                const leftPx = Math.min(min, seekWidth);
                const time = (leftPx / seekWidth) * this.__video.duration;
                const roundedTime = Math.round(time);
                const roundedTimeWithMulti = Math.round(time * MULTI);
                seek.setAttribute('data-value', roundedTimeWithMulti.toString());
                const [minutes, seconds] = this.formatTime(roundedTime);
                tooltip.textContent = `${minutes}:${seconds}`;
                tooltip.style.left = `${leftPx}px`;
            }
        };

        document.addEventListener('mousemove', this.__seekMouseMoveHandler);

        this.__mouseDownMouseMoveHandler = () => {
            const roundedTimeWithMulti = parseInt(seek.dataset.value || '0', 10);
            this.__video.currentTime = roundedTimeWithMulti / MULTI;
            progress.value = roundedTimeWithMulti;
        };

        let mouseDownInitiated = false;
        let isAutoStoppedVideo = false;

        seek.addEventListener('mousedown', (event) => {
            if (!this.isPaused()) {
                this.__video.pause();
                isAutoStoppedVideo = true;
            }
            this.__seekMouseMoveHandler?.(event);
            if (this.__mouseDownMouseMoveHandler) {
                this.__mouseDownMouseMoveHandler();
                document.addEventListener('mousemove', this.__mouseDownMouseMoveHandler);
            }
            mouseDownInitiated = true;
        });

        document.addEventListener('mouseup', async () => {
            if (mouseDownInitiated) {
                if (this.__mouseDownMouseMoveHandler) {
                    document.removeEventListener('mousemove', this.__mouseDownMouseMoveHandler);
                }
                mouseDownInitiated = false;
                if (isAutoStoppedVideo) {
                    try {
                        await this.__video.play();
                    } catch (e) {
                        // console.log(e);
                    }
                    isAutoStoppedVideo = false;
                }
            }
        });
    }

    initSound() {
        const mute = this.getById('mute') as HTMLButtonElement;
        const volume = this.getById('volume') as HTMLInputElement;

        volume?.addEventListener('input', () => {
            const value = parseFloat(volume.value);
            this.__parent.muted = value === 0;
            this.__video.muted = value === 0;
            this.__volume = value;
            this.__video.volume = value;
        });

        this.__toggleVideoMute = () => {
            if (this.__video.muted) {
                this.__parent.muted = false;
                this.__video.muted = false;
                volume.value = this.__volume.toString();
                this.__video.volume = this.__volume;
            } else {
                this.__parent.muted = true;
                this.__video.muted = true;
                volume.value = '0';
                this.__video.volume = 0;
            }
        };

        mute?.addEventListener('click', this.__toggleVideoMute);

        this.__video.addEventListener('volumechange', () => {
            if (this.__video.volume === 0) {
                mute.innerHTML = muteIcon;
            } else {
                mute.innerHTML = unmuteIcon;
            }
        });

        const disableMuteHandler = () => {
            const __video = this.__video as {
                mozHasAudio?: boolean;
                webkitAudioDecodedByteCount?: number;
                audioTracks?: [];
            };
            const hasAudio =
                __video.mozHasAudio ||
                Boolean(__video.webkitAudioDecodedByteCount) ||
                Boolean(__video.audioTracks && __video.audioTracks.length);
            mute.classList.add('video__mute--visible');
            mute.disabled = this.__parent.autoplay && !hasAudio;
        };

        this.__video.addEventListener('loadeddata', () => {
            if (this.__parent.muted) {
                disableMuteHandler();
            }
        });

        this.__video.addEventListener('play', () => {
            if (!this.__parent.muted) {
                disableMuteHandler();
            }
        });

        this.__video.addEventListener('loadedmetadata', () => {
            const value = parseInt(volume.value, 10);
            this.__video.volume = value / 100;
        });
    }

    isPaused() {
        return this.__video.paused || this.__video.ended;
    }

    initPlay() {
        const play = this.getById('play') as HTMLButtonElement;

        // maybe change to this.isPlaying
        let isPlaying = false;

        this.__toggleVideoPlay = () => {
            try {
                if (!isPlaying && this.isPaused()) {
                    this.__video.play();
                } else {
                    this.__video.pause();
                }
            } catch (e) {
                // console.log(e);
            }
        };

        play?.addEventListener('click', this.__toggleVideoPlay);
        this.__video.addEventListener('click', this.__toggleVideoPlay);
        this.__video.addEventListener('playing', () => {
            play.innerHTML = pauseIcon;
            isPlaying = true;
        });
        this.__video.addEventListener('pause', () => {
            play.innerHTML = playIcon;
            isPlaying = false;
        });
        this.__video.addEventListener('ended', () => {
            play.innerHTML = playIcon;
        });
    }

    initRepeat() {
        const repeat = this.getById('repeat') as HTMLButtonElement;

        this.__toggleVideoLoop = () => {
            if (this.__parent.loop) {
                repeat.classList.remove('video__repeat--active');
                this.__parent.loop = false;
                this.__video.loop = false;
            } else {
                repeat.classList.add('video__repeat--active');
                this.__parent.loop = true;
                this.__video.loop = true;
            }
        };

        repeat.addEventListener('click', this.__toggleVideoLoop);
    }

    initFullscreen() {
        const fullscreen = this.getById('fullscreen') as HTMLButtonElement;
        const container = this.getById('container') as HTMLDivElement;

        this.__toggleVideoFullscreen = async () => {
            if (container) {
                if (document.fullscreenElement) {
                    await document.exitFullscreen();
                } else {
                    await container.requestFullscreen();
                }
            }
        };

        fullscreen?.addEventListener('click', this.__toggleVideoFullscreen);

        this.__fullscreenChangeHandler = () => {
            if (document.fullscreenElement) {
                container.classList.add('video--fullscreen');
            } else {
                container.classList.remove('video--fullscreen');
            }
        };

        if (this.__fullscreenChangeHandler) {
            document.addEventListener('fullscreenchange', this.__fullscreenChangeHandler);
        }
    }

    initHotkey() {
        this.__keydownHotkeyHandler = (event: KeyboardEvent) => {
            if (event.code === 'Space' && this.__toggleVideoPlay) {
                this.__toggleVideoPlay();
            } else if (event.code === 'KeyM' && this.__toggleVideoMute) {
                this.__toggleVideoMute();
            } else if (event.code === 'KeyL' && this.__toggleVideoLoop) {
                this.__toggleVideoLoop();
            } else if (event.code === 'KeyF' && this.__toggleVideoFullscreen) {
                this.__toggleVideoFullscreen();
            }
        };

        document.addEventListener('keydown', this.__keydownHotkeyHandler);
    }

    constructor(__this: VideoWc) {
        this.__parent = __this;
        this.__video = this.getById('video') as HTMLVideoElement;

        this.initOverlay();
        this.initProgress();
        this.initFullscreen();
        this.initHotkey();
        this.initRepeat();
        this.initSound();
        this.initTime();
        this.initPlay();
    }

    destroy() {
        if (this.__seekMouseMoveHandler) {
            document.removeEventListener('mousemove', this.__seekMouseMoveHandler);
        }
        if (this.__mouseDownMouseMoveHandler) {
            document.removeEventListener('mousemove', this.__mouseDownMouseMoveHandler);
        }
        if (this.__fullscreenChangeHandler) {
            document.removeEventListener('fullscreenchange', this.__fullscreenChangeHandler);
        }
        if (this.__keydownHotkeyHandler) {
            document.removeEventListener('keydown', this.__keydownHotkeyHandler);
        }
        if (this.__overlayMouseMoveHandler) {
            window.removeEventListener('mousemove', this.__overlayMouseMoveHandler);
        }
    }
}
