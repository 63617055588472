import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Listing as ApiListing, Post as ApiPost } from 'src/types/redditApi';
import { Node as ApiNode } from 'src/middlewares/reddit';
import { RedditContext, ThemeContext } from 'src/contexts';
import { LocationState, Theme } from 'src/types/common';
import OriginalButton from 'src/components/OriginalButton/OriginalButton';
import PrevButton from 'src/components/PrevButton/PrevButton';
import { useHotkeys } from 'react-hotkeys-hook';
import Skeleton from 'src/components/Skeleton/Skeleton';
import SearchButton from 'src/components/SearchButton/SearchButton';
import MediaContent from 'src/components/MediaContent/MediaContent';
import MediaNavigation from 'src/components/MediaNavigation/MediaNavigation';
import {
    addAndRemoveClass,
    findByDataId,
    getPrevSiblingsHeight,
    scrollPage
} from 'src/utils/common';
import Icon from 'src/components/Icon/Icon';
import Menu from 'src/components/Menu/Menu';
import { useTitle } from 'src/hooks/useTitle';
import DownloadButton from 'src/components/DownloadButton/DownloadButton';
import './Media.scss';

interface Props {}

const Media: React.FC<Props> = ({}: Props) => {
    const reddit = useContext(RedditContext);

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { theme, applyTheme } = useContext(ThemeContext);

    const idRef = useRef<string | undefined>();
    idRef.current = id;

    const [prevLocation] = useState((location.state as LocationState)?.backgroundLocation) || {};

    const navigateBack = () => navigate(prevLocation?.pathname || '/');

    useHotkeys('esc', navigateBack);

    const ref = useRef<HTMLDivElement>(null);

    const { loading: isLoading, value } = useAsync(
        async () =>
            await reddit.get<ApiNode<ApiListing<ApiPost>>>(`/api/info`, {
                id
            }),
        [id]
    );

    useEffect(() => {
        applyTheme(Theme.DARK);
        return () => {
            applyTheme(theme);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            const el = findByDataId(idRef.current as string);
            if (el) {
                const sumHeight = getPrevSiblingsHeight(el);
                addAndRemoveClass(el, 'post--focus-highlight');
                scrollPage(0, sumHeight - 10);
            }
        };
    }, []);

    const data = value?.data?.children?.[0]?.data;

    const { subreddit: category, author, title, permalink } = data || {};

    useTitle(title || 'Post');

    return (
        <div className="media">
            <div className="media__menu">
                <Menu />
            </div>
            <div className="media__top">
                <div className="media__left">
                    <PrevButton onClick={navigateBack} />
                    {isLoading ? (
                        <div className="media__title">
                            <div className="media__info">
                                <div className="media__category">
                                    <Skeleton width={60} height={13} />
                                </div>
                                <div className="media__author">
                                    <Skeleton width={60} height={13} />
                                </div>
                            </div>
                            <div className="media__text">
                                <Skeleton width={250} height={18} />
                            </div>
                        </div>
                    ) : (
                        <div className="media__title">
                            <div className="media__info">
                                <Link className="media__category" to={`/r/${category}`}>
                                    r/{category}
                                </Link>
                                {author === '[deleted]' ? (
                                    <div className="media__author">by u/{author}</div>
                                ) : (
                                    <Link className="media__author" to={`/u/${author}`}>
                                        by u/{author}
                                    </Link>
                                )}
                            </div>
                            <div className="media__text" title={title}>
                                {title}
                            </div>
                        </div>
                    )}
                </div>
                <div className="media__right">
                    <SearchButton />
                    <OriginalButton url={`https://reddit.com${permalink}`} />
                </div>
            </div>
            <div className="media__content" ref={ref}>
                {isLoading ? (
                    <div className="media__loading">
                        <Icon type="loading" />
                    </div>
                ) : (
                    <MediaContent data={data} id={id} wrapperRef={ref} />
                )}
            </div>
            <div className="media__bottom">
                <DownloadButton onClick={() => {}} />
            </div>
            <MediaNavigation id={id} location={prevLocation} />
        </div>
    );
};

export default Media;
