const getFromStorage = <T = Record<string, any>>(key: string, storage: Storage, parse: boolean) => {
    const item = storage.getItem(key);
    if (parse) {
        if (item) {
            try {
                return JSON.parse(item) as T;
            } catch (e) {
                return null;
            }
        }
    }
    return item;
};

// export const getFromSessionStorage = <T = Record<string, any>>(key: string, parse = false) => {
//     return getFromStorage<T>(key, sessionStorage, parse);
// };

export const getFromLocalStorage = <T = Record<string, any>>(key: string, parse = false) => {
    return getFromStorage<T>(key, localStorage, parse);
};

const setToStorage = (key: string, value: any, storage: Storage) => {
    if (typeof value === 'string') {
        storage.setItem(key, value);
    } else if (typeof value === 'undefined') {
        storage.removeItem(key);
    } else {
        storage.setItem(key, JSON.stringify(value));
    }
};

// export const setToSessionStorage = (key: string, value: any) => {
//     setToStorage(key, value, sessionStorage);
// }

export const setToLocalStorage = (key: string, value: any) => {
    setToStorage(key, value, localStorage);
};

const removeFromStorage = (key: string, storage: Storage) => {
    storage.removeItem(key);
};

// export const removeFromSessionStorage = (key: string) => {
//     setToStorage(key, sessionStorage);
// }

export const removeFromLocalStorage = (key: string) => {
    removeFromStorage(key, localStorage);
};
