import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';

interface Props {
    url?: string;
    children: ReactNode | string;
    onClick?: () => void;
    blank?: boolean;
    width?: number;
    style?: React.CSSProperties;
    outline?: boolean;
    disabled?: boolean;
    title?: string;
    native?: boolean;
    state?: any;
}

const Button = ({
    url,
    onClick,
    children,
    blank = false,
    width,
    style,
    outline = false,
    disabled = false,
    title,
    native = false,
    state
}: Props) => {
    if (onClick) {
        return (
            <button
                onClick={onClick}
                className={`button ${outline ? 'button--outline' : ''} ${
                    disabled ? 'button--disabled' : ''
                }`}
                style={{ width, ...style }}
                disabled={disabled}
                title={title}
            >
                {children}
            </button>
        );
    }

    return native ? (
        <a // eslint-disable-line react/jsx-no-target-blank
            href={url as string}
            target={blank ? '_blank' : undefined}
            rel={blank ? 'noreferrer' : undefined}
            className={`button ${outline ? 'button--outline' : ''} ${
                disabled ? 'button--disabled' : ''
            }`}
            style={{ width, ...style }}
            title={title}
        >
            {children}
        </a>
    ) : (
        <Link
            to={url as string}
            state={state}
            target={blank ? '_blank' : undefined}
            rel={blank ? 'noreferrer' : undefined}
            className={`button ${outline ? 'button--outline' : ''} ${
                disabled ? 'button--disabled' : ''
            }`}
            style={{ width, ...style }}
            title={title}
        >
            {children}
        </Link>
    );
};

export default Button;
