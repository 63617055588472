import React from 'react';
import './NewPostsButton.scss';

interface Props {
    isVisible: boolean;
    onClick: () => void;
}

const NewPostsButton: React.FC<Props> = ({ onClick, isVisible }: Props) => (
    <button
        type="button"
        onClick={onClick}
        className={`new-posts ${isVisible ? 'new-posts--active' : ''}`}
    >
        New Posts
    </button>
);

export default NewPostsButton;
