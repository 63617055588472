import React, { ReactNode, useRef } from 'react';
import { ChainContext } from 'src/contexts';
import { ChainLink } from 'src/types/common';

interface Props {
    children: ReactNode;
}

const ChainCtx: React.FC<Props> = ({ children }: Props) => {
    const chainRef = useRef<ChainLink>({});

    return (
        <ChainContext.Provider
            value={{
                chain: chainRef.current,
                addToChain: (id: string, left?: string, right?: string) => {
                    chainRef.current[id] = {
                        left,
                        right
                    };
                },
                resetChain: () => {
                    chainRef.current = {};
                }
            }}
        >
            {children}
        </ChainContext.Provider>
    );
};

export default ChainCtx;
