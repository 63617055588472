import React, { useContext, useLayoutEffect } from 'react';
import { Navigate, Route, Routes, UNSAFE_NavigationContext, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { BrowserHistory } from 'history';
import New from 'src/pages/New/New';
import Hot from 'src/pages/Hot/Hot';
import Top from 'src/pages/Top/Top';
import Best from 'src/pages/Best/Best';
import User from 'src/pages/User/User';
import Login from 'src/pages/Login/Login';
import Search from 'src/pages/Search/Search';
import Category from 'src/pages/Category/Category';
import Media from 'src/components/Media/Media';
import Context from 'src/components/Context/Context';
import { LocationState } from 'src/types/common';

ReactGA.initialize('G-19HFBGFX0Y');

const App: React.FC = () => {
    const location = useLocation();
    const { backgroundLocation } = (location?.state as LocationState) || {};

    const navigation = useContext(UNSAFE_NavigationContext).navigator as BrowserHistory;

    useLayoutEffect(() => {
        if (navigation) {
            navigation.listen(({ location }) => {
                ReactGA.set({ page: location.pathname });
                ReactGA.send({ hitType: 'pageview', page: location.pathname });
            });
        }
    }, [navigation]);

    return (
        <Context>
            <Routes location={backgroundLocation || location}>
                <Route path="/" element={<Navigate replace to="/best" />} />
                <Route path="/new" element={<New />} />
                <Route path="/top" element={<Top />} />
                <Route path="/hot" element={<Hot />} />
                <Route path="/best" element={<Best />} />
                <Route path="/search" element={<Search />} />
                <Route path="/u/:id" element={<User />} />
                <Route path="/r/:id" element={<Category />} />
                <Route path="/media/:id" element={<Media />} />
                <Route path="/login" element={<Login />} />
                {/*<Route path="*" element={<NotFound />} />*/}
            </Routes>
            {backgroundLocation && (
                <Routes>
                    <Route
                        path="/media/:id"
                        element={
                            <div className="fixed-page">
                                <Media />
                            </div>
                        }
                    />
                </Routes>
            )}
        </Context>
    );
};

export default App;
