import React, { ReactNode, useRef } from 'react';
import { CacheContext } from 'src/contexts';

interface Props {
    children: ReactNode;
}

const CacheCtx: React.FC<Props> = ({ children }: Props) => {
    const cacheRef = useRef<Record<string, any>>({});

    return (
        <CacheContext.Provider
            value={{
                cache: cacheRef.current,
                addToCache: (key: string, value: any) => {
                    cacheRef.current[key] = value;
                },
                resetCache: () => {
                    cacheRef.current = {};
                }
            }}
        >
            {children}
        </CacheContext.Provider>
    );
};

export default CacheCtx;
