import Controls from './controls';
import { fullscreenIcon, muteIcon, pauseIcon, playIcon, repeatIcon, unmuteIcon } from './icons';
import { Ref } from 'react';
// @ts-ignore
import styles from '!!css-loader!sass-loader!./styles.scss';

export const tagName = 'r-video';

export default class VideoWc extends HTMLElement {
    __root: ShadowRoot;
    __controls?: Controls;

    constructor() {
        super();
        this.__root = this.attachShadow({ mode: 'closed' });
    }

    get src() {
        return this.getAttribute('src') || '';
    }

    get poster() {
        return this.getAttribute('poster') || '';
    }

    get width() {
        return this.getAttribute('width') || '';
    }

    get height() {
        return this.getAttribute('height') || '';
    }

    setAttr(name: string, value: boolean) {
        if (value) {
            // this.__root.getElementById('video')?.setAttribute(name, '');
            this.setAttribute(name, '');
        } else {
            // this.__root.getElementById('video')?.removeAttribute(name);
            this.removeAttribute(name);
        }
    }

    get muted() {
        return this.hasAttribute('muted');
    }

    set muted(value) {
        this.setAttr('muted', value);
    }

    get autoplay() {
        return this.hasAttribute('autoplay');
    }

    set autoplay(value) {
        this.setAttr('autoplay', value);
    }

    get loop() {
        return this.hasAttribute('loop');
    }

    set loop(value) {
        this.setAttr('loop', value);
    }

    getWidth(width: string) {
        return Math.max(parseInt(width, 10), 400).toString();
    }

    getHeight(height: string) {
        return Math.max(parseInt(height, 10), 225).toString();
    }

    getStyle() {
        return `width: ${this.getWidth(this.width)}px; height: ${this.getHeight(this.height)}px; `;
    }

    getType() {
        const ext = this.src.split('.').pop();
        let type = 'mp4';
        if (ext === 'webm') {
            type = 'webm';
        } else if (ext === 'ogg') {
            type = 'ogg';
        }
        return `video/${type}`;
    }

    render() {
        this.__root.innerHTML = `
            <style>${styles.toString()}</style>
            <div class="video video--loading" id="container" style="${this.getStyle()}">
                <div class="video__source">
                    <video
                        id="video"
                        ${this.autoplay ? `autoplay` : ''}
                        ${this.loop ? `loop` : ''}
                        ${this.muted ? `muted` : ''}
                        poster="${this.poster}"
                    >
                        <source src="${this.src}" type="${this.getType()}" />
                    </video>
                </div>
                <div class="video__overlay video__overlay--visible" id="overlay">
                    <div class="video__bottom">
                        <div class="video__panel">
                            <div class="video__left">
                                <button type="button" class="video__play" id="play">
                                    ${this.autoplay ? pauseIcon : playIcon}
                                </button>
                                <button type="button" class="video__repeat ${
                                    this.loop ? 'video__repeat--active' : ''
                                }" id="repeat">
                                    ${repeatIcon}
                                </button>
                            </div>
                            <div class="video__time">
                                <time id="elapsed">00:00</time> / <time id="duration">00:00</time>
                            </div>
                            <div class="video__right">
                                <div class="video__sound">
                                    <button type="button" class="video__mute" id="mute">
                                        ${this.muted ? muteIcon : unmuteIcon}
                                    </button>
                                    <div class="video__volume">
                                        <input type="range" min="0" max="1" step="0.01" value="${
                                            this.muted ? 0 : 0.75
                                        }" id="volume" />
                                    </div>
                                </div>
                                <button type="button" class="video__fullscreen" id="fullscreen">
                                    ${fullscreenIcon}
                                </button>
                            </div>
                        </div>
                        <div class="video__progress">
                            <div class="video__seek" id="seek" data-value="0"></div>
                            <progress class="video__line" id="progress"></progress>
                            <div class="video__tooltip" id="tooltip">00:00</div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    connectedCallback() {
        this.render();
        this.__controls = new Controls(this);
    }

    disconnectedCallback() {
        this.__controls?.destroy();
    }

    static get observedAttributes() {
        return ['width', 'height'];
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        const container = this.__root.getElementById('container') as HTMLDivElement;

        if (container) {
            if (name === 'width') {
                container.style.width = `${this.getWidth(newValue)}px`;
            } else if (name === 'height') {
                container.style.height = `${this.getHeight(newValue)}px`;
            }
        }
    }
}

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            [tagName]: Attributes;
        }

        interface Attributes {
            ref?: Ref<VideoWc>;
            muted?: boolean;
            loop?: boolean;
            autoplay?: boolean;
            width?: number;
            height?: number;
            src?: string;
            poster?: string;
        }
    }
}
