import { useState, useEffect, useCallback } from 'react';

const useWindowDimensions = () => {
    const hasWindow = typeof window !== 'undefined';

    const getWindowDimensions = useCallback(
        () => ({
            width: hasWindow ? window.innerWidth : undefined,
            height: hasWindow ? window.innerHeight : undefined
        }),
        [hasWindow]
    );

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            const handleResize = () => {
                setWindowDimensions(getWindowDimensions());
            };
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [getWindowDimensions, hasWindow]);

    return windowDimensions;
};

export default useWindowDimensions;
