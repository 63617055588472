import React, { useContext } from 'react';
import { ThemeContext } from 'src/contexts';
import Button from 'src/components/Button/Button';
import Icon from 'src/components/Icon/Icon';
import './ThemeButton.scss';

const ThemeButton: React.FC = () => {
    const { toggleTheme } = useContext(ThemeContext);
    return (
        <div className="theme-button">
            <Button onClick={() => toggleTheme()}>
                <Icon type="light-bulb" />
            </Button>
        </div>
    );
};

export default ThemeButton;
