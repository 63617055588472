import React, { useContext } from 'react';
import { useAsync, useSearchParam } from 'react-use';
import ReactGA from 'react-ga4';
import {
    removeFromLocalStorage as removeFromLS,
    setToLocalStorage as setToLS,
    getFromLocalStorage as getFromLS
} from 'src/utils/storage';
import {
    AUTH_APP_ID,
    LS_REDDIT_EXPRS_NAME,
    LS_REDDIT_GTYPE_NAME,
    LS_REDDIT_RSTKN_NAME,
    LS_REDDIT_STATE_NAME,
    LS_REDDIT_TOKEN_NAME
} from 'src/constants';
import { RedditContext } from 'src/contexts';
import { useNavigate } from 'react-router-dom';
import Loading from 'src/components/Loading/Loading';

const Login: React.FC = () => {
    const navigate = useNavigate();

    const code = useSearchParam('code');
    const error = useSearchParam('error');
    const state = useSearchParam('state');

    const prevState = getFromLS(LS_REDDIT_STATE_NAME);

    const reddit = useContext(RedditContext);

    useAsync(async () => {
        if (code && !error && state === prevState) {
            removeFromLS(LS_REDDIT_TOKEN_NAME);
            removeFromLS(LS_REDDIT_EXPRS_NAME);
            removeFromLS(LS_REDDIT_GTYPE_NAME);
            removeFromLS(LS_REDDIT_RSTKN_NAME);
            reddit.code = code;
            await reddit.getNewToken();
            ReactGA.event({
                category: 'User',
                action: 'Logged in'
            });
            navigate('/new');
        } else if (error) {
            ReactGA.event({
                category: 'User',
                action: 'Got error on log in'
            });
            navigate('/new');
        } else {
            const newState = new Date().valueOf().toString();
            setToLS(LS_REDDIT_STATE_NAME, newState);
            const urlInstance = new URL('https://www.reddit.com/api/v1/authorize');
            urlInstance.searchParams.append('client_id', AUTH_APP_ID);
            urlInstance.searchParams.append('response_type', 'code');
            urlInstance.searchParams.append('state', newState);
            urlInstance.searchParams.append('redirect_uri', encodeURI(`${window.location.href}`));
            urlInstance.searchParams.append('duration', 'permanent');
            urlInstance.searchParams.append('scope', 'identity mysubreddits read history'); // todo add user viewing
            ReactGA.event({
                category: 'User',
                action: 'Requested log in'
            });
            window.location.href = urlInstance.toString();
        }
    }, []);

    return <Loading />;
};

export default Login;
