import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from 'src/components/Button/Button';
import Icon from 'src/components/Icon/Icon';
import './SearchButton.scss';

const SearchButton: React.FC = () => {
    const location = useLocation();

    return (
        <div className="search-button">
            <Button url="/search" state={{ previousLocation: location }} outline>
                <Icon type="search" />
            </Button>
        </div>
    );
};

export default SearchButton;
