import React from 'react';
import './RedLabel.scss';

interface Props {
    text: string;
}

const RedLabel: React.FC<Props> = ({ text }: Props) => <div className="red-label">{text}</div>;

export default RedLabel;
