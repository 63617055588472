import React, { useContext } from 'react';
import Page from 'src/components/Page/Page';
import Header from 'src/components/Header/Header';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { User as ApiUser } from 'src/types/redditApi';
import PrevButton from 'src/components/PrevButton/PrevButton';
import OriginalButton from 'src/components/OriginalButton/OriginalButton';
import ThemeButton from 'src/components/ThemeButton/ThemeButton';
import FilterButton from 'src/components/FilterButton/FilterButton';
import SearchButton from 'src/components/SearchButton/SearchButton';
import { htmlDecode } from 'src/utils/common';
import { Filter } from 'src/types/grid';
import useCookieFilter from 'src/hooks/useCookieFilter';
import { useTitle } from 'src/hooks/useTitle';
import { RedditContext } from 'src/contexts';
import { Node as ApiNode } from 'src/middlewares/reddit';

interface Props {}

const User: React.FC<Props> = ({}: Props) => {
    const reddit = useContext(RedditContext);

    const { id } = useParams();

    const { value } = useAsync(
        async () => await reddit.get<ApiNode<ApiUser>>(`/user/${id}/about.json`),
        [id]
    );

    const title = value?.data?.subreddit?.title;

    const [filter, setFilter] = useCookieFilter<Partial<Filter>>();

    const isLoaded = typeof title !== 'undefined';

    useTitle(isLoaded ? `u/${id}` : 'User');

    return id ? (
        <Page
            apiUrl={`/user/${id}`}
            top={
                <Header
                    title={title ? htmlDecode(title) : id}
                    category={isLoaded ? `u/${id}` : undefined}
                    left={
                        <>
                            <PrevButton />
                            <FilterButton filter={filter} setFilter={setFilter} />
                        </>
                    }
                    right={
                        <>
                            <SearchButton />
                            <OriginalButton url={`https://reddit.com/u/${id}`} />
                        </>
                    }
                />
            }
            filter={filter}
            bottom={<ThemeButton />}
        />
    ) : null;
};

export default User;
