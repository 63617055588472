import React from 'react';
import MenuLink from './MenuLink';
import Profile from 'src/components/Profile/Profile';
import { APP_NAME } from 'src/constants';
import './Menu.scss';

interface Props {}

const Menu: React.FC<Props> = ({}: Props) => {
    return (
        <div className="menu">
            <div className="menu__left">
                <div className="menu__logo">{APP_NAME}</div>
                <MenuLink to="/best">Best</MenuLink>
                <MenuLink to="/hot">Hot</MenuLink>
                <MenuLink to="/new">New</MenuLink>
                <MenuLink to="/top">Top</MenuLink>
            </div>
            <div className="menu__right">
                <Profile />
            </div>
        </div>
    );
};

export default Menu;
