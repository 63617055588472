import { Sizing, SizingMap } from 'src/types/common';
import { Filter } from 'src/types/grid';

export const AUTH_APP_ID = process.env.REACT_APP_AUTH_APP_ID as string;
export const ENV_APP_URL = process.env.REACT_APP_APP_URL as string | undefined;
export const ENV_API_URL = process.env.REACT_APP_API_URL as string | undefined;

export const APP_URL = ENV_APP_URL || 'http://localhost:3000';
export const API_URL = ENV_API_URL || 'http://localhost:8080/api';

export const APP_NAME = 'Scrl.me';

export const LS_REDDIT_TOKEN_NAME = 'reddit_access_token';
export const LS_REDDIT_RSTKN_NAME = 'reddit_refresh_token';
export const LS_REDDIT_EXPRS_NAME = 'reddit_expires_date';
export const LS_REDDIT_GTYPE_NAME = 'reddit_grant_type';

export const LS_REDDIT_STATE_NAME = 'reddit_state_string';

export const LS_REDGIFS_TOKEN_NAME = 'redgifs_access_token';
export const LS_REDGIFS_EXPRS_NAME = 'redgifs_expires_date';
export const LS_REDGIFS_GTYPE_NAME = 'redgifs_grant_type';

export const DEFAULT_LIMIT = 100;

export const DEFAULT_FILTER: Filter = {
    images: true,
    videos: true,
    galleries: true,
    query: '',
    imagesSize: null,
    videosSize: null,
    nsfw: true
};

export const IMAGE_SIZING: SizingMap = {
    [Sizing.LARGE]: [2601, Infinity],
    [Sizing.MEDIUM]: [1401, 2600],
    [Sizing.SMALL]: [0, 1400]
};

export const VIDEO_SIZING: SizingMap = {
    [Sizing.LARGE]: [2601, Infinity],
    [Sizing.MEDIUM]: [1401, 2600],
    [Sizing.SMALL]: [0, 1400]
};
