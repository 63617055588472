import React from 'react';
import Icon from 'src/components/Icon/Icon';
import Button from 'src/components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocationState } from 'src/types/common';
import './PrevButton.scss';

interface Props {
    onClick?: () => void;
    disabled?: boolean;
}

const PrevButton: React.FC<Props> = ({ onClick, disabled = false }: Props) => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const locationState = (state as LocationState) || {};

    const { searchQuery } = locationState;

    const previousLocation = (locationState?.previousLocation?.state as LocationState)
        ?.previousLocation;

    const opts = {
        state: {
            ...(searchQuery ? { searchQuery } : {}),
            ...(previousLocation ? { previousLocation } : {})
        }
    };

    return (
        <div className="prev-button">
            <Button
                onClick={
                    onClick
                        ? onClick
                        : () => {
                              navigate(locationState?.previousLocation?.pathname || '/', opts);
                          }
                }
                disabled={disabled}
            >
                <Icon type="previous" />
            </Button>
        </div>
    );
};

export default PrevButton;
