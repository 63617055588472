import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Reddit, { Node as ApiNode } from 'src/middlewares/reddit';
import { Filter, GridPost } from 'src/types/grid';
import { Listing as ApiListing, Post as ApiPost } from 'src/types/redditApi';
import { useAsync } from 'react-use';
import { CacheContext, ChainContext } from 'src/contexts';
import { DEFAULT_FILTER, DEFAULT_LIMIT } from 'src/constants';
import useScroll from 'src/hooks/useScroll';
import { prepareFilteredItems, prepareGridPosts } from 'src/utils/grid';

interface Params {
    items: GridPost[];
    isLoading: boolean;
    showBeforeItems: () => void;
    beforeItems: GridPost[];
}

interface Props {
    apiUrl: string;
    reddit: Reddit;
    children: (params: Params) => ReactElement;
    filter?: Partial<Filter>;
    isScrollUpdateReady: (event: Event) => boolean;
    isHeightUpdateReady: () => boolean;
}

const Provider: React.FC<Props> = ({
    apiUrl,
    children,
    reddit,
    filter: userFilter,
    isScrollUpdateReady,
    isHeightUpdateReady
}: Props) => {
    const [after, setAfter] = useState<string>();
    const [items, setItems] = useState<GridPost[]>([]);

    const { cache, addToCache, resetCache } = useContext(CacheContext);
    const { addToChain, resetChain } = useContext(ChainContext);

    const first = useRef<ApiNode<ApiPost>>();
    const last = useRef<ApiNode<ApiPost>>();

    const uniqueImages = useRef<Record<string, boolean>>({});

    const get = (opts: Record<string, any>) =>
        reddit?.get<ApiNode<ApiListing<ApiPost>>>(apiUrl, opts);

    const [isLoading, setIsLoading] = useState(true);

    const { loading: isLoadingRawAfterItems, value: rawAfterItems } = useAsync(async () => {
        const response = await get({ after, limit: DEFAULT_LIMIT });
        const newRawItems = response?.data?.children;
        if (!first.current) {
            first.current = newRawItems?.[0];
        }
        last.current = newRawItems?.[newRawItems?.length - 1];
        return newRawItems;
    }, [after]);

    const requestNextListing = () => {
        const { kind, data } = last.current || {};
        if (kind && data?.id) {
            setAfter(`${kind}_${data?.id}`);
        }
    };

    const count = useRef<number>(0);

    useEffect(() => {
        if (rawAfterItems && rawAfterItems.length > 0) {
            const newItems = prepareGridPosts(rawAfterItems, cache, ({ id }, img) =>
                addToCache(id, img)
            );
            setItems((prevItems) => [...prevItems, ...newItems]);
            count.current += 1;
        } else if (rawAfterItems && rawAfterItems.length === 0) {
            setIsLoading(false);
        }
    }, [rawAfterItems]); // eslint-disable-line react-hooks/exhaustive-deps

    const [beforeItems, setBeforeItems] = useState<GridPost[]>([]);

    const getBeforeItems = async () => {
        const { kind, data } = first.current || {};
        const before = `${kind}_${data?.id}`;
        const response = await get({ before, limit: DEFAULT_LIMIT });
        const newRawItems = response?.data?.children;
        if (newRawItems?.length > 0) {
            first.current = newRawItems?.[0];
            const newItems = prepareGridPosts(newRawItems, cache, ({ id }, img) =>
                addToCache(id, img)
            );
            setBeforeItems((prevItems) => [...newItems, ...prevItems]);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(getBeforeItems, 1000 * 60);
        return () => clearInterval(intervalId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const showBeforeItems = () => {
        setItems((prevItems) => [...beforeItems, ...prevItems]);
        setBeforeItems([]);
    };

    useScroll((event) => {
        if (isScrollUpdateReady(event)) {
            requestNextListing();
        }
    });

    const filter = { ...DEFAULT_FILTER, ...userFilter };

    const filterStr = JSON.stringify(filter);

    const filteredItems = useMemo(
        () => prepareFilteredItems(items, filter, {}),
        [items, filterStr]
    ); // eslint-disable-line react-hooks/exhaustive-deps

    const filteredBeforeItems = useMemo(
        () => prepareFilteredItems(beforeItems, filter, uniqueImages.current),
        [beforeItems, filterStr]
    ); // eslint-disable-line react-hooks/exhaustive-deps

    // create nav chain
    useEffect(() => {
        resetChain();
        filteredItems?.forEach(({ fullId }, index) => {
            const prevId = filteredItems?.[index - 1]?.fullId;
            const nextId = filteredItems?.[index + 1]?.fullId;
            addToChain(fullId, prevId, nextId);
        });
    }, [filteredItems]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            items.length > 0 &&
            !isLoadingRawAfterItems &&
            rawAfterItems &&
            rawAfterItems.length !== 0 &&
            isHeightUpdateReady()
        ) {
            if (count.current < 10) {
                requestNextListing();
            } else {
                setIsLoading(false);
            }
        }
    }, [filterStr, items?.length, isLoadingRawAfterItems, rawAfterItems, isHeightUpdateReady]);

    useEffect(() => {
        return () => {
            last.current = undefined;
            setAfter(undefined);
            setItems([]);
            resetChain();
            resetCache();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return children({
        items: filteredItems,
        isLoading,
        showBeforeItems,
        beforeItems: filteredBeforeItems
    });
};

export default Provider;
