import React from 'react';
import Icon from 'src/components/Icon/Icon';
import './Loading.scss';

interface Props {}

const Loading: React.FC<Props> = ({}: Props) => (
    <div className="loading">
        <Icon type="loading" />
    </div>
);

export default Loading;
