import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { Resource, Type } from 'src/types/common';
import { Location } from 'history';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Filter, GridPost } from 'src/types/grid';
import Highlight from 'src/components/Highlight/Highlight';
import { humanResource } from 'src/utils/text';
import './Post.scss';

import 'swiper/css';
import 'swiper/css/navigation';

interface Props {
    item: GridPost;
    colWidth: number;
    location: Location;
    filter?: Partial<Filter>;
}

const Post: React.FC<Props> = ({ item, colWidth, location, filter }: Props) => {
    const {
        id,
        fullId,
        url,
        images,
        author,
        width,
        height,
        title,
        category,
        date,
        type,
        resource
    } = item;
    return (
        <div className="post" data-id={fullId} data-resource={resource.toLowerCase()}>
            <div className="post__inner">
                <Link
                    to={url}
                    state={{ backgroundLocation: location }}
                    className={`post__media ${type === Type.VIDEO ? 'post__media--video' : ''}`}
                >
                    {images?.length === 1 ? (
                        <LazyLoadImage
                            effect="opacity"
                            className="post__img"
                            alt={title}
                            height={(colWidth * height) / width}
                            src={images?.[0]}
                            width={colWidth}
                        />
                    ) : (
                        <Swiper
                            modules={[Navigation, Pagination]}
                            navigation={{
                                prevEl: `#swiper${id}Prev`,
                                nextEl: `#swiper${id}Next`
                            }}
                            pagination={{
                                el: `#swiper${id}Pagination`,
                                type: 'fraction'
                            }}
                            noSwiping
                            noSwipingClass="swiper-slide"
                        >
                            {images?.map((image) => (
                                <SwiperSlide
                                    key={`${id}_${image}`}
                                    className="post__slide"
                                    style={{ height: (colWidth * height) / width }}
                                >
                                    <LazyLoadImage
                                        effect="opacity"
                                        className="post__img post__img--swiper"
                                        alt={title}
                                        height={(colWidth * height) / width}
                                        src={image}
                                        width={colWidth}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}
                </Link>
                <div
                    className={`post__navigation ${
                        images?.length > 1 ? '' : 'post__navigation--hidden'
                    }`}
                >
                    <button type="button" className="post__prev" id={`swiper${id}Prev`} />
                    <button type="button" className="post__next" id={`swiper${id}Next`} />
                    <div
                        className="post__label"
                        id={`swiper${id}Pagination`}
                        style={{ right: 15, top: 10 }}
                    />
                </div>
                <div className="post__label" style={{ left: 0, bottom: 0 }}>
                    {date}
                </div>
                <div className="post__label" style={{ right: 0, bottom: 0 }}>
                    {width} &times; {height}
                </div>
                {resource !== Resource.REDDIT && (
                    <div className="post__resource" style={{ left: 15, top: 10 }}>
                        {humanResource[resource]}
                    </div>
                )}
            </div>
            <div className="post__title">
                <div className="post__info">
                    {location.pathname === `/r/${category}` ? (
                        <div className="post__category">
                            r/
                            <Highlight text={category} query={filter?.query} />
                        </div>
                    ) : (
                        <Link
                            className="post__category"
                            state={{ previousLocation: location }}
                            to={`/r/${category}`}
                        >
                            r/
                            <Highlight text={category} query={filter?.query} />
                        </Link>
                    )}
                    {author === '[deleted]' || location.pathname === `/u/${author}` ? (
                        <div className="post__author">
                            by u/
                            <Highlight text={author} query={filter?.query} />
                        </div>
                    ) : (
                        <Link
                            className="post__author"
                            to={`/u/${author}`}
                            state={{ previousLocation: location }}
                        >
                            by u/
                            <Highlight text={author} query={filter?.query} />
                        </Link>
                    )}
                </div>
                <div className="post__text">
                    <Highlight text={title} query={filter?.query} />
                </div>
            </div>
        </div>
    );
};

export default Post;
