import React, { useEffect, useState } from 'react';
import Reddit, { Node as ApiNode } from 'src/middlewares/reddit';
import { useAsync, useDebounce } from 'react-use';
import { DEFAULT_LIMIT } from 'src/constants';
import { Listing as ApiListing, Subreddit as ApiSubreddit } from 'src/types/redditApi';
import { Link, useLocation } from 'react-router-dom';
import { formatNumber, randomIntFromInterval, scrollPage } from 'src/utils/common';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Icon from 'src/components/Icon/Icon';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as DefaultIcon } from 'src/svgs/DefaultIcon.svg';
import RedLabel from 'src/components/RedLabel/RedLabel';
import { LocationState } from 'src/types/common';
import './SearchPage.scss';

interface Props {
    reddit: Reddit;
}

const ICON_SIZE = 40;

const SearchPage: React.FC<Props> = ({ reddit }: Props) => {
    const location = useLocation();

    const { searchQuery } = (location.state as LocationState) || {};

    useEffect(() => scrollPage(0, 0), []);

    const [query, setQuery] = useState(searchQuery || '');
    const [q, setQ] = useState(query);

    useDebounce(
        () => {
            setQ(query);
        },
        600,
        [query]
    );

    const { loading: isLoading, value } = useAsync(async () => {
        if (q.length > 2) {
            return reddit.get<ApiNode<ApiListing<ApiSubreddit>>>('/search', {
                limit: DEFAULT_LIMIT,
                q,
                type: 'sr'
            });
        }
        return {} as ApiNode<ApiListing<ApiSubreddit>>;
    }, [q]);

    // TODO fix back navigation problem (maybe through a route)

    return (
        <div className="search">
            <div className="search__field">
                <input
                    autoFocus
                    type="text"
                    className="search__input"
                    placeholder="Search Reddit"
                    value={query}
                    onChange={(event) => setQuery(event?.target?.value)}
                />
                <button type="button" className="search__clear" onClick={() => setQuery('')}>
                    <Icon type="close" />
                </button>
            </div>
            {isLoading ? (
                <div className="search__results">
                    {isLoading &&
                        Array.from(Array(20).keys()).map((index) => (
                            <div key={index} className="search__result">
                                <div className="search__left" style={{ height: ICON_SIZE }}>
                                    <Skeleton
                                        width={ICON_SIZE}
                                        height={ICON_SIZE}
                                        style={{ borderRadius: ICON_SIZE }}
                                        shineWidth={500}
                                    />
                                </div>
                                <div className="search__right">
                                    <div className="search__info">
                                        <div className="search__name">
                                            <Skeleton width={60} height={13} shineWidth={500} />
                                        </div>
                                        <div className="search__followers">
                                            <Skeleton width={90} height={13} shineWidth={500} />
                                        </div>
                                    </div>
                                    <div className="search__desc">
                                        <Skeleton
                                            width={250 * (1 + randomIntFromInterval(1, 100) / 200)}
                                            height={20}
                                            shineWidth={500}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            ) : (
                <div className="search__results">
                    {value?.data?.children?.map(({ data }) => (
                        <Link
                            key={data?.id}
                            to={`/r/${data?.display_name}`}
                            state={{
                                previousLocation: location,
                                searchQuery: query
                            }}
                            className="search__result"
                        >
                            <div className="search__left" style={{ height: ICON_SIZE }}>
                                {data?.icon_img ? (
                                    <LazyLoadImage
                                        effect="opacity"
                                        src={data?.icon_img}
                                        width={ICON_SIZE}
                                        height={ICON_SIZE}
                                        style={{ objectFit: 'cover', borderRadius: ICON_SIZE }}
                                    />
                                ) : (
                                    <DefaultIcon width={ICON_SIZE} height={ICON_SIZE} />
                                )}
                            </div>
                            <div className="search__right">
                                <div className="search__info">
                                    <div className="search__name">r/{data?.display_name}</div>
                                    {data?.over18 && (
                                        <div className="search__label">
                                            <RedLabel text="nsfw" />
                                        </div>
                                    )}
                                    <div className="search__followers">
                                        {formatNumber(data?.subscribers)} Members
                                    </div>
                                </div>
                                {data?.public_description ? (
                                    <div className="search__desc">{data?.public_description}</div>
                                ) : (
                                    <div className="search__desc search__desc--no-desc">
                                        No description
                                    </div>
                                )}
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchPage;
