import React from 'react';
import { ChainLink, Theme } from 'src/types/common';
import Reddit from 'src/middlewares/reddit';
import Redgifs from 'src/middlewares/redgifs';

export const ThemeContext = React.createContext({
    theme: Theme.LIGHT,
    setTheme: (theme: Theme) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
    applyTheme: (theme: Theme) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
    toggleTheme: () => {}
});

export const CacheContext = React.createContext({
    cache: {} as Record<string, any>,
    addToCache: (key: string, value: any) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
    resetCache: () => {}
});

export const ChainContext = React.createContext({
    chain: {} as ChainLink,
    addToChain: (id: string, left?: string, right?: string) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
    resetChain: () => {}
});

export const RedditContext = React.createContext({} as Reddit);

export const RedgifsContext = React.createContext({} as Redgifs);
