import React, { ReactNode, useLayoutEffect, useState } from 'react';
import { ThemeContext } from 'src/contexts';
import { useCookie } from 'react-use';
import { Theme } from 'src/types/common';

interface Props {
    children: ReactNode;
}

const ThemeCtx: React.FC<Props> = ({ children }: Props) => {
    const [themeCookie, setThemeCookie] = useCookie('theme');
    const [themeState, setThemeState] = useState((themeCookie as Theme) || Theme.LIGHT);

    const switchTheme = (newTheme: Theme) => {
        if (newTheme === Theme.LIGHT) {
            document.body.classList.remove('dark');
        } else {
            document.body.classList.add('dark');
        }
    };

    useLayoutEffect(() => {
        switchTheme(themeState);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const setTheme = (newTheme: Theme) => {
        applyTheme(newTheme);
        setThemeState(newTheme);
        setThemeCookie(newTheme);
    };

    const applyTheme = (newTheme: Theme) => {
        switchTheme(newTheme);
    };

    const toggleTheme = () => {
        setTheme(themeState === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
    };

    return (
        <ThemeContext.Provider
            value={{
                theme: themeState,
                setTheme,
                applyTheme,
                toggleTheme
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeCtx;
