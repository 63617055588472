import { useEffect, useRef } from 'react';
import { APP_NAME } from 'src/constants';

export const useTitle = (value: string) => {
    const ref = useRef('');
    useEffect(() => {
        ref.current = document.title;
        if (document.title !== value) {
            document.title = `${value} | ${APP_NAME}`;
        }
        return () => {
            document.title = ref.current;
        };
    }, [value]);
};
