import React from 'react';
import Page from 'src/components/Page/Page';
import Header from 'src/components/Header/Header';
import OriginalButton from 'src/components/OriginalButton/OriginalButton';
import ThemeButton from 'src/components/ThemeButton/ThemeButton';
import FilterButton from 'src/components/FilterButton/FilterButton';
import SearchButton from 'src/components/SearchButton/SearchButton';
import PrevButton from 'src/components/PrevButton/PrevButton';
import useCookieFilter from 'src/hooks/useCookieFilter';
import { Filter } from 'src/types/grid';
import { useTitle } from 'src/hooks/useTitle';

interface Props {
    url: '/new' | '/top' | '/best' | '/hot' | '/rising' | '/random';
    title: string;
}

const Feed: React.FC<Props> = ({ url, title }: Props) => {
    const [filter, setFilter] = useCookieFilter<Partial<Filter>>();

    useTitle(title || 'Post');

    return (
        <Page
            apiUrl={url}
            top={
                <Header
                    title={title}
                    left={
                        <>
                            <PrevButton disabled />
                            <FilterButton filter={filter} setFilter={setFilter} />
                        </>
                    }
                    right={
                        <>
                            <SearchButton />
                            <OriginalButton url={`https://reddit.com/new`} />
                        </>
                    }
                />
            }
            filter={filter}
            bottom={<ThemeButton />}
        />
    );
};

export default Feed;
