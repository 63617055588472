import React, { ReactNode } from 'react';
import { RedgifsContext } from 'src/contexts';
import {
    API_URL,
    LS_REDGIFS_EXPRS_NAME,
    LS_REDGIFS_GTYPE_NAME,
    LS_REDGIFS_TOKEN_NAME
} from 'src/constants';
import { getFromLocalStorage as getFromLS, setToLocalStorage as setToLS } from 'src/utils/storage';
import Redgifs from 'src/middlewares/redgifs';

interface Props {
    children: ReactNode;
}

const RedgifsCtx: React.FC<Props> = ({ children }: Props) => {
    return (
        <RedgifsContext.Provider
            value={
                new Redgifs({
                    userAgent: navigator.userAgent,
                    baseUrl: `${API_URL}/redgifs`,
                    authUrl: `${API_URL}/redgifs/auth`,
                    token: getFromLS(LS_REDGIFS_TOKEN_NAME),
                    tokenExpireDate: parseInt(getFromLS(LS_REDGIFS_EXPRS_NAME) || '0', 10),
                    setTokenCallback: ({ accessToken, expiresInDate, grantType }) => {
                        setToLS(LS_REDGIFS_GTYPE_NAME, grantType);
                        setToLS(LS_REDGIFS_TOKEN_NAME, accessToken);
                        setToLS(LS_REDGIFS_EXPRS_NAME, expiresInDate.toString());
                    }
                })
            }
        >
            {children}
        </RedgifsContext.Provider>
    );
};

export default RedgifsCtx;
