import React, { useRef } from 'react';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import Masonry from 'react-masonry-css';
import Post from 'src/components/Post/Post';
import PostSkeleton from 'src/components/Post/PostSkeleton';
import { randomIntFromInterval } from 'src/utils/common';
import { Filter, GridPost } from 'src/types/grid';
import { useLocation } from 'react-router-dom';
import './Grid.scss';

interface Props {
    items: GridPost[];
    isLoading: boolean;
    filter?: Partial<Filter>;
}

const Grid: React.FC<Props> = ({ items, isLoading, filter }: Props) => {
    const location = useLocation();
    const ref = useRef<HTMLDivElement>(null);

    const { width: windowWidth = 0 } = useWindowDimensions();

    const gridWidth = ref?.current?.clientWidth || 0;

    let cols = 6;

    if (windowWidth <= 574) {
        cols = 1;
    } else if (windowWidth <= 767) {
        cols = 2;
    } else if (windowWidth <= 1259) {
        cols = 3;
    } else if (windowWidth <= 1559) {
        cols = 4;
    } else if (windowWidth <= 2159) {
        cols = 5;
    }

    const colWidth = (gridWidth - 20) / cols - 20;

    const skeletons = useRef(
        Array(100)
            .fill(0)
            .map(() => Math.floor(400 * (1 + randomIntFromInterval(1, 100) / 200)))
    );

    return (
        <div className="grid" ref={ref}>
            <Masonry breakpointCols={cols} className="grid__content" columnClassName="grid__column">
                {/*<PostSkeleton*/}
                {/*    width={colWidth}*/}
                {/*    height={400}*/}
                {/*/>*/}
                {items?.map((item) => (
                    <Post
                        key={item?.id}
                        item={item}
                        colWidth={colWidth}
                        location={location}
                        filter={filter}
                    />
                ))}
                {isLoading &&
                    skeletons.current.map((height, index) => (
                        <PostSkeleton key={index} width={colWidth} height={height} />
                    ))}
            </Masonry>
        </div>
    );
};

export default Grid;
