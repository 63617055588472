import { useEffect } from 'react';

const useScroll = (handleScroll: (event: Event) => void, firstRun = false) => {
    useEffect(() => {
        if (firstRun) {
            handleScroll(new Event('scroll'));
        }
        document.body.addEventListener('scroll', handleScroll);
        return () => {
            document.body.removeEventListener('scroll', handleScroll);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useScroll;
