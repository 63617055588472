import { Location } from 'history';

export interface OAuth {
    username?: string;
    password?: string;
}

export enum Theme {
    LIGHT = 'LIGHT',
    DARK = 'DARK'
}

export interface Size {
    width: number;
    height: number;
}

export enum Type {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    GALLERY = 'GALLERY'
}

export enum Resource {
    REDDIT = 'REDDIT',
    REDGIFS = 'REDGIFS',
    GFYCAT = 'GFYCAT',
    IMGUR = 'IMGUR'
}

export enum Sizing {
    LARGE = 'LARGE',
    MEDIUM = 'MEDIUM',
    SMALL = 'SMALL'
}

export type ChainLink = Record<
    string,
    {
        left?: string;
        right?: string;
    }
>;

export interface LocationState {
    backgroundLocation?: Location;
    previousLocation?: Location;
    searchQuery?: string;
}

export interface SizingMap {
    [Sizing.LARGE]: [number, number];
    [Sizing.MEDIUM]: [number, number];
    [Sizing.SMALL]: [number, number];
}

export interface Image {
    url: string;
    width: number;
    height: number;
}
