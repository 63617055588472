import React from 'react';

interface Props {
    text: string;
    query?: string;
}

const Highlight: React.FC<Props> = ({ text, query }: Props) => {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    const style = { backgroundColor: 'rgb(255, 242, 189)' };

    if (!query) {
        return <>{text}</>;
    }

    return (
        <>
            {parts.map((part, index) => (
                <span key={index} style={part.toLowerCase() === query.toLowerCase() ? style : {}}>
                    {part}
                </span>
            ))}
        </>
    );
};

export default Highlight;
