import React, { useId } from 'react';
import './Skeleton.scss';

interface Props {
    width: number;
    height: number;
    style?: React.CSSProperties;
    shineWidth?: number;
    shineMs?: number;
}

const Skeleton: React.FC<Props> = ({ width, height, style, shineWidth, shineMs = 2000 }: Props) => {
    const id = useId().replace(/:/g, '');

    const half = Math.floor((shineWidth ? shineWidth : width) / 2);
    const full = Math.floor(shineWidth ? shineWidth : width);

    return (
        <>
            <style>
                {`
                    @keyframes shine-${id} {
                        0% { background-position: -${full}px }
                        40%, 100% { background-position: ${Math.floor(width)}px }
                    }

                    .skeleton[data-id="${id}"] {
                        --half: ${half}px;
                        --full: ${full}px;
                    }
                `}
            </style>
            <div
                className="skeleton"
                data-id={id}
                style={{
                    width,
                    height,
                    borderRadius: height ? Math.floor(height * 0.3) : 4,
                    backgroundSize: full * 4,
                    animation: `shine-${id} ${shineMs}ms infinite linear`,
                    ...style
                }}
            />
        </>
    );
};

export default Skeleton;
