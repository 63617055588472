import React, { useContext } from 'react';
import { RedditContext } from 'src/contexts';
import {
    LS_REDDIT_EXPRS_NAME,
    LS_REDDIT_GTYPE_NAME,
    LS_REDDIT_RSTKN_NAME,
    LS_REDDIT_TOKEN_NAME
} from 'src/constants';
import { removeFromLocalStorage as removeFromLS } from 'src/utils/storage';
import { useAsync } from 'react-use';
import { User } from 'src/types/redditApi';
import Skeleton from 'src/components/Skeleton/Skeleton';
import { GrantType } from 'src/middlewares/reddit';
import './Profile.scss';
import ReactGA from 'react-ga4';

interface Props {}

const Profile: React.FC<Props> = ({}: Props) => {
    const reddit = useContext(RedditContext);

    const isLoggedIn =
        (reddit.grantType === GrantType.AUTHORIZATION_CODE ||
            reddit.grantType === GrantType.REFRESH_TOKEN) &&
        reddit.token &&
        reddit.tokenExpireDate;

    const { value: data, loading: isLoading } = useAsync(async () => {
        if (isLoggedIn) {
            return await reddit.get<User>('/api/v1/me');
        }
    }, [isLoggedIn]);

    const handleLogout = () => {
        removeFromLS(LS_REDDIT_GTYPE_NAME);
        removeFromLS(LS_REDDIT_TOKEN_NAME);
        removeFromLS(LS_REDDIT_EXPRS_NAME);
        removeFromLS(LS_REDDIT_RSTKN_NAME);
        ReactGA.event({
            category: 'User',
            action: 'Logged out'
        });
        window.location.href = '/best';
    };

    if (isLoading) {
        return (
            <div className="profile">
                <Skeleton width={120} height={12} />
            </div>
        );
    }

    return (
        <div className="profile">
            {isLoggedIn ? (
                <div className="profile__user">
                    {data?.name && (
                        <div className="profile__name">
                            Hi, <span>{data?.name}</span>
                        </div>
                    )}
                    <button type="button" className="profile__action" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            ) : (
                <a href="/login" className="profile__action">
                    Login with Reddit
                </a>
            )}
        </div>
    );
};

export default Profile;
