import React, { ReactNode, useContext, useEffect } from 'react';
import Grid from 'src/components/Grid/Grid';
import Provider from 'src/components/Provider/Provider';
import { Filter } from 'src/types/grid';
import { addAndRemoveClass, findByDataId, scrollPage } from 'src/utils/common';
import NewPostsButton from 'src/components/NewPostsButton/NewPostsButton';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { getColumnsMinHeight } from 'src/utils/grid';
import { RedditContext } from 'src/contexts';
import './Page.scss';

interface Props {
    apiUrl: string;
    top?: ReactNode;
    bottom?: ReactNode;
    filter?: Partial<Filter>;
}

const Page: React.FC<Props> = ({ apiUrl, top, bottom, filter = {} }: Props) => {
    const reddit = useContext(RedditContext);

    useEffect(() => scrollPage(0, 0), []);

    const { height: windowHeight = 0 } = useWindowDimensions();

    // todo add nothing found if it's not loading and has zero items

    return (
        <Provider
            apiUrl={apiUrl}
            reddit={reddit}
            filter={filter}
            isScrollUpdateReady={(event) => {
                const scrollTop = (event?.currentTarget as HTMLElement).scrollTop;
                return scrollTop > getColumnsMinHeight() - windowHeight * 2;
            }}
            isHeightUpdateReady={() => getColumnsMinHeight() <= document.body.clientHeight * 2}
        >
            {({ items, isLoading, showBeforeItems, beforeItems }) => (
                <div className="page">
                    {isLoading}
                    {top}
                    <Grid items={items} isLoading={isLoading} filter={filter} />
                    {bottom}
                    <NewPostsButton
                        isVisible={beforeItems?.length > 0}
                        onClick={() => {
                            if (beforeItems?.length > 0) {
                                showBeforeItems();
                                setTimeout(() => {
                                    beforeItems?.forEach(({ fullId }) => {
                                        const el = findByDataId(fullId);
                                        if (el) {
                                            addAndRemoveClass(el, 'post--added-highlight');
                                        }
                                    });
                                    scrollPage(0, 0);
                                }, 0);
                            }
                        }}
                    />
                </div>
            )}
        </Provider>
    );
};

export default Page;
