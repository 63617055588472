export enum GrantType {
    AUTHORIZATION_CODE = 'authorization_code',
    CLIENT_CREDENTIALS = 'client_credentials',
    PASSWORD = 'password',
    REFRESH_TOKEN = 'refresh_token'
}

interface CallbackParams {
    accessToken: string;
    expiresInDate: number;
    grantType: string;
    refreshToken: string;
}

type SetTokenCallback = undefined | ((params: CallbackParams) => void);

export interface Params {
    appId?: string;
    appSecret?: string;
    code?: string;
    duration?: string;
    username?: string;
    password?: string;
    token?: string | null;
    refreshToken?: string | null;
    tokenExpireDate?: number;
    userAgent?: string;
    baseUrl?: string;
    authUrl?: string;
    secondsBeforeExpires?: number;
    setTokenCallback?: SetTokenCallback;
}

export interface Node<T> {
    data: T;
    kind: string;
}

export default class Reddit {
    appId?: string;
    appSecret?: string;
    code?: string;
    duration?: string;
    username?: string;
    password?: string;
    token: string | null;
    refreshToken: string | null;
    tokenExpireDate: number;
    userAgent?: string;
    baseUrl: string;
    authUrl: string;
    grantType: GrantType;

    private __accessPromise: Promise<any> | null = null;
    private __secondsBeforeExpires = 180;

    private __setTokenCallback: SetTokenCallback;

    /**
     * Provide **appId** and **appSecret** to create a pair to retrieve an access token.
     *
     * Made optional to allow for custom authorization on dedicated server.
     *
     * Provide **token** and **tokenExpireDate** if have any to be used in further requests.
     *
     * Provide **secondsBeforeExpires** to prevent race conditions with token. Default: 180
     *
     * Provide **code** to use *grant_type = authorization_code*,
     *
     * Provide **duration** to pass the duration along with **code**,
     *
     * Provide **username** and **password** to use *grant_type = password*,
     *
     * Will use *grant_type = client_credentials* if none of the above is provided.
     *
     * You can specify **baseUrl** for API calls. Default: 'https://oauth.reddit.com'
     *
     * You can specify **authUrl** for token retrieval. Default: 'https://www.reddit.com/api/v1/access_token'
     *
     * You can provide **setTokenCallback** in order to store accessToken and expiresIn.
     *
     * Provide **userAgent** to be passed down to Reddit calls.
     */
    constructor(params: Params) {
        this.appId = params.appId;
        this.appSecret = params.appSecret;
        this.code = params.code;
        this.duration = params.duration;
        this.username = params.username;
        this.password = params.password;
        this.userAgent = params.userAgent;
        this.token = null;
        if (typeof params.token !== 'undefined') {
            this.token = params.token;
        }
        this.refreshToken = null;
        if (typeof params.refreshToken !== 'undefined') {
            this.refreshToken = params.refreshToken;
        }
        this.tokenExpireDate = 0;
        if (typeof params.tokenExpireDate !== 'undefined') {
            this.tokenExpireDate = params.tokenExpireDate;
        }
        this.baseUrl = 'https://oauth.reddit.com';
        if (typeof params.baseUrl !== 'undefined') {
            this.baseUrl = params.baseUrl;
        }
        this.authUrl = 'https://www.reddit.com/api/v1/access_token';
        if (typeof params.authUrl !== 'undefined') {
            this.authUrl = params.authUrl;
        }
        if (typeof params.secondsBeforeExpires !== 'undefined') {
            this.__secondsBeforeExpires = params.secondsBeforeExpires;
        }
        if (typeof params.setTokenCallback !== 'undefined') {
            this.__setTokenCallback = params.setTokenCallback;
        }
        this.grantType = GrantType.CLIENT_CREDENTIALS;
        if (this.username && this.password) {
            this.grantType = GrantType.PASSWORD;
        } else if (this.code) {
            this.grantType = GrantType.AUTHORIZATION_CODE;
        } else if (this.refreshToken) {
            this.grantType = GrantType.REFRESH_TOKEN;
        }
    }

    private __getCurrentSeconds() {
        return Math.floor(new Date().valueOf() / 1000);
    }

    private __isExpired(expiresDate: number) {
        return expiresDate - this.__secondsBeforeExpires < this.__getCurrentSeconds();
    }

    private async __getToken(callback?: SetTokenCallback) {
        const urlInstance = new URL(`${this.authUrl}`);
        this.grantType = GrantType.CLIENT_CREDENTIALS;
        if (this.username && this.password) {
            this.grantType = GrantType.PASSWORD;
            urlInstance.searchParams.append('username', this.username);
            urlInstance.searchParams.append('password', this.password);
        } else if (this.code) {
            this.grantType = GrantType.AUTHORIZATION_CODE;
            urlInstance.searchParams.append('code', this.code);
            if (this.duration) {
                urlInstance.searchParams.append('duration', this.duration);
            }
        } else if (this.refreshToken) {
            this.grantType = GrantType.REFRESH_TOKEN;
            urlInstance.searchParams.append('refresh_token', this.refreshToken);
        }
        urlInstance.searchParams.append('grant_type', this.grantType);
        const headers: HeadersInit = {};
        if (this.appId && this.appSecret) {
            const pair = `${this.appId}:${this.appSecret}`;
            const basicToken = Buffer.from(pair).toString('base64');
            headers['Authorization'] = `Basic ${basicToken}`;
        }
        if (this.userAgent) {
            headers['user-agent'] = this.userAgent;
        }
        const response = await fetch(urlInstance.toString(), { headers, method: 'POST' });
        const {
            access_token: accessToken,
            expires_in: expiresIn = 0,
            refresh_token: refreshToken
        } = await response.json();
        if (accessToken) {
            const expiresInDate = expiresIn + this.__getCurrentSeconds();
            this.token = accessToken;
            this.tokenExpireDate = expiresInDate;
            this.__setTokenCallback?.({
                accessToken,
                expiresInDate,
                grantType: this.grantType,
                refreshToken
            });
            callback?.({ accessToken, expiresInDate, grantType: this.grantType, refreshToken });
        }
    }

    private async __fetch(url: string, opts?: Record<string, any>, method?: string) {
        if ((!this.token || this.__isExpired(this.tokenExpireDate)) && !this.__accessPromise) {
            this.__accessPromise = this.__getToken();
        }
        if (this.__accessPromise) {
            await this.__accessPromise;
            this.__accessPromise = null;
        }
        const urlInstance = new URL(`${this.baseUrl}${url}`);
        Object.keys(opts || {}).forEach((key) => {
            if (opts?.[key]) {
                urlInstance.searchParams.append(key, opts[key]);
            }
        });
        if (!/\.json$/.test(url)) {
            urlInstance.searchParams.append('api_type', 'json');
        }
        const headers: HeadersInit = {};
        headers['Content-Type'] = 'application/json';
        headers['authorization'] = `bearer ${this.token}`;
        if (this.userAgent) {
            headers['user-agent'] = this.userAgent;
        }
        const response = await fetch(urlInstance.toString(), {
            headers,
            method
        });
        return await response.json();
    }

    public async get<T = Record<string, any>>(url: string, opts?: Record<string, any>) {
        return (await this.__fetch(url, opts, 'GET')) as T;
    }

    public async getNewToken(callback?: SetTokenCallback) {
        this.__accessPromise = null;
        this.token = null;
        this.tokenExpireDate = 0;
        await this.__getToken(callback);
    }
}
