import React, { RefObject, useContext } from 'react';
import { MediaMetadata, Post as ApiPost } from 'src/types/redditApi';
import Picture from 'src/components/Picture/Picture';
import { CacheContext } from 'src/contexts';
import Video from 'src/components/Video/Video';
import Redgifs from 'src/components/Redgifs/Redgifs';
import Gallery from 'src/components/Gallery/Gallery';

interface Props {
    data?: ApiPost;
    id?: string;
    wrapperRef: RefObject<HTMLDivElement>;
}

const MediaContent: React.FC<Props> = ({ data, id, wrapperRef }: Props) => {
    const { cache } = useContext(CacheContext);

    const galleryList: MediaMetadata[] = [];

    let source = id ? cache?.[id] : {};

    if (data) {
        source = data?.preview?.images?.[0]?.source;

        const { media_metadata: metadata = {}, gallery_data: galleryData } = data;

        galleryData?.items?.forEach(({ media_id }) => {
            const galleryItem = metadata?.[media_id]?.s;
            if (galleryItem) {
                galleryList.push(galleryItem);
            }
        });
    }

    const { url, width, height } = source || {};

    const isRedgifs = data?.url && /redgifs\.com/.test(data?.url);
    const isImgur = data?.url && /imgur\.com\/(.+)$/.test(data?.url);
    const isImage = url && /\.(jpg|jpeg|png|webp)/i.test(url) && !isImgur;
    const isGif = data?.url && /(reddit\.com|redd\.it)\/(.+)\.(gifv|gif)$/i.test(data?.url);

    const gifUrl = data?.preview?.images?.[0]?.variants?.mp4?.source?.url;

    if (isRedgifs) {
        return <Redgifs url={data?.url} />;
    } else if (width && height && isImage) {
        return (
            <Picture
                wrapperRef={wrapperRef}
                url={url?.replace(/&amp;/g, '&')}
                origSize={{ width, height }}
            />
        );
    } else if (galleryList?.length > 0) {
        return <Gallery list={galleryList} wrapperRef={wrapperRef} />;
    } else if (data && data.url && url && width && height && isImgur) {
        if (/.+\.(jpg|jpeg|png|webp)$/i.test(data.url)) {
            return (
                <Picture
                    wrapperRef={wrapperRef}
                    url={data.url?.replace(/&amp;/g, '&')}
                    origSize={{ width, height }}
                />
            );
        }
        let dataUrl = data.url.replace(/https:\/\/imgur.com/i, 'https://i.imgur.com');
        if (/.+\.(gif|gifv)$/i.test(dataUrl)) {
            dataUrl = dataUrl.replace(/(.+)\.(.+)$/, '$1.mp4');
        } else {
            dataUrl = `${dataUrl}.mp4`;
        }
        return (
            <Video
                origSize={{ width, height }}
                poster={url?.replace(/&amp;/g, '&')}
                url={dataUrl}
            />
        );
    } else if (data && data.url && url && gifUrl && width && height && isGif) {
        return (
            <Video
                poster={url?.replace(/&amp;/g, '&')}
                origSize={{ width, height }}
                url={gifUrl?.replace(/&amp;/g, '&')}
            />
        );
    }

    return null;
};

export default MediaContent;
