import React, { ReactNode } from 'react';
import { RedditContext } from 'src/contexts';
import Reddit from 'src/middlewares/reddit';
import {
    API_URL,
    APP_URL,
    LS_REDDIT_EXPRS_NAME,
    LS_REDDIT_GTYPE_NAME,
    LS_REDDIT_RSTKN_NAME,
    LS_REDDIT_TOKEN_NAME
} from 'src/constants';
import { getFromLocalStorage as getFromLS, setToLocalStorage as setToLS } from 'src/utils/storage';

interface Props {
    children: ReactNode;
}

const RedditCtx: React.FC<Props> = ({ children }: Props) => {
    return (
        <RedditContext.Provider
            value={
                new Reddit({
                    userAgent: `ScrlMe/1.0.0 (${APP_URL})`,
                    baseUrl: `${API_URL}/reddit`,
                    authUrl: `${API_URL}/reddit/auth`,
                    token: getFromLS(LS_REDDIT_TOKEN_NAME),
                    refreshToken: getFromLS(LS_REDDIT_RSTKN_NAME),
                    tokenExpireDate: parseInt(getFromLS(LS_REDDIT_EXPRS_NAME) || '0', 10),
                    setTokenCallback: ({ accessToken, expiresInDate, grantType, refreshToken }) => {
                        setToLS(LS_REDDIT_GTYPE_NAME, grantType);
                        setToLS(LS_REDDIT_TOKEN_NAME, accessToken);
                        setToLS(LS_REDDIT_RSTKN_NAME, refreshToken);
                        setToLS(LS_REDDIT_EXPRS_NAME, expiresInDate.toString());
                    }
                })
            }
        >
            {children}
        </RedditContext.Provider>
    );
};

export default RedditCtx;
