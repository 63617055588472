import React from 'react';
import './Icon.scss';

interface Props {
    type: string;
}

const Icon: React.FC<Props> = ({ type }: Props) => {
    return <div className={`icon icon--${type}`} />;
};

export default Icon;
