import React, { RefObject, useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useResize from 'src/hooks/useResize';
import { Size } from 'src/types/common';
import { getImageFitDimensions } from 'src/utils/common';
import Scroll from 'src/components/Scroll/Scroll';
import './Picture.scss';
import { useHotkeys } from 'react-hotkeys-hook';

interface Coordinates {
    x: number;
    y: number;
}

interface Props {
    wrapperRef?: RefObject<HTMLDivElement>;
    url: string;
    origSize: Size;
}

const Picture: React.FC<Props> = ({ wrapperRef, url, origSize }: Props) => {
    const innerRef = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLDivElement>(null);

    const coordinates = useRef<Coordinates>({ x: 0, y: 0 });

    const [isZoom, setIsZoom] = useState(false);

    const [parentSize, setParentSize] = useState<Size>({ width: 0, height: 0 });

    useResize(() => {
        const { clientWidth: width, clientHeight: height } = parentRef?.current || {};
        if (width && height) {
            setParentSize({ width, height });
        }
    }, true);

    const fitSize = getImageFitDimensions(origSize, parentSize);

    let cursor = 'default';

    if (origSize.height > parentSize.height || origSize.width > parentSize.width) {
        cursor = isZoom ? 'grab' : 'zoom-in';
    }

    useEffect(() => {
        if (isZoom) {
            const { clientWidth, clientHeight } = parentRef?.current || {};
            if (clientWidth && clientHeight) {
                const { x, y } = coordinates.current || {};
                const xMulti = origSize.width / fitSize.width;
                const yMulti = origSize.height / fitSize.height;
                const scrollX = Math.max(0, x * xMulti - clientWidth / 2);
                const scrollY = Math.max(0, y * yMulti - clientHeight / 2);
                parentRef?.current?.scrollTo(scrollX, scrollY);
            }
        }
    }, [isZoom]); // eslint-disable-line react-hooks/exhaustive-deps

    const width = isZoom ? origSize.width : fitSize.width;
    const height = isZoom ? origSize.height : fitSize.height;

    useHotkeys('+', () => setIsZoom(true));
    useHotkeys('=', () => setIsZoom(true));
    useHotkeys('-', () => setIsZoom(false));
    useHotkeys('_', () => setIsZoom(false));

    return (
        <Scroll innerRef={parentRef}>
            <div ref={innerRef} className="picture" style={{ height, width }}>
                <div className="picture__wrapper">
                    <LazyLoadImage
                        effect="opacity"
                        className="picture__img"
                        style={{
                            cursor,
                            aspectRatio: `auto ${width} / ${height}`
                        }}
                        onClick={() => setIsZoom((prevIsZoom) => !prevIsZoom)}
                        onMouseMove={(event) => {
                            const {
                                offsetLeft: wrapperOffsetLeft = 0,
                                offsetTop: wrapperOffsetTop = 0
                            } = wrapperRef?.current || {};
                            const {
                                offsetLeft: parentOffsetLeft = 0,
                                offsetTop: parentOffsetTop = 0
                            } = parentRef?.current || {};
                            const { offsetLeft = 0, offsetTop = 0 } =
                                (event.target as HTMLImageElement) || {};
                            const x =
                                event.clientX - offsetLeft - parentOffsetLeft - wrapperOffsetLeft;
                            const y =
                                event.clientY - offsetTop - parentOffsetTop - wrapperOffsetTop;
                            coordinates.current = { x: x > 0 ? x : 0, y: y > 0 ? y : 0 };
                        }}
                        height={height}
                        src={url}
                        width={width}
                    />
                </div>
            </div>
        </Scroll>
    );
};

export default Picture;
