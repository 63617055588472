import React, { useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import Button from 'src/components/Button/Button';
import Icon from 'src/components/Icon/Icon';
import FilterPopup from 'src/components/FilterPopup/FilterPopup';
import { Filter } from 'src/types/grid';
import { sleep } from 'src/utils/common';
import Count from 'src/components/Count/Count';
import { DEFAULT_FILTER } from 'src/constants';
import './FIlterButton.scss';

interface Props {
    filter: Partial<Filter>;
    setFilter: React.Dispatch<React.SetStateAction<Partial<Filter>>>;
}

const FilterButton: React.FC<Props> = ({ filter, setFilter }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const count = useMemo(() => {
        let result = 0;
        Object.keys(filter || {}).forEach((key) => {
            if (filter?.[key as keyof Filter] !== DEFAULT_FILTER?.[key as keyof Filter]) {
                result += 1;
            }
        });
        return result;
    }, [filter]);

    return (
        <div className="filter-button">
            <Count number={count}>
                <Button
                    onClick={async () => {
                        if (!isOpen) {
                            ReactGA.event({
                                category: 'User',
                                action: 'Clicked filter button'
                            });
                            await sleep(100);
                            setIsOpen(true);
                        }
                    }}
                    outline
                >
                    <Icon type="filter" />
                </Button>
            </Count>
            <FilterPopup
                count={count}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                filter={filter}
                setFilter={setFilter}
            />
        </div>
    );
};

export default FilterButton;
