import React, { useRef, useState } from 'react';
import Button from 'src/components/Button/Button';
import Icon from 'src/components/Icon/Icon';
import Scroll from 'src/components/Scroll/Scroll';
import './Video.scss';
import { getImageFitDimensions } from 'src/utils/common';
import useResize from 'src/hooks/useResize';
import { Size } from 'src/types/common';
import Player from 'src/components/Video/Player';
import { useHotkeys } from 'react-hotkeys-hook';
// import Player from 'src/components/Player/Player';

interface Props {
    url: string;
    origSize: Size;
    poster?: string;
}

const Video: React.FC<Props> = ({ url, poster, origSize }: Props) => {
    const parentRef = useRef<HTMLDivElement>(null);

    const [zoom, setZoom] = useState(2);

    const [parentSize, setParentSize] = useState<Size>({ width: 0, height: 0 });

    useResize(() => {
        const { clientWidth: width, clientHeight: height } = parentRef?.current || {};
        if (width && height) {
            setParentSize({ width, height });
        }
    }, true);

    const fitSize = getImageFitDimensions(origSize, parentSize);

    const width = Math.floor((fitSize.width * zoom) / 2);
    const height = Math.floor((fitSize.height * zoom) / 2);

    const handleZoomIn = () => setZoom((prevZoom) => Math.min(4, prevZoom * 2));
    const handleZoomOut = () => setZoom((prevZoom) => Math.max(1, prevZoom / 2));

    useHotkeys('+', handleZoomIn);
    useHotkeys('=', handleZoomIn);
    useHotkeys('-', handleZoomOut);
    useHotkeys('_', handleZoomOut);

    useHotkeys('0', () => setZoom(2));

    return (
        <div className={`video ${zoom > 2 ? 'video--scroll' : ''}`}>
            <Scroll innerRef={parentRef}>
                <div className="video__content" style={{ height, width }}>
                    <Player width={width} height={height} url={url} poster={poster} />
                </div>
            </Scroll>
            <div className="video__right">
                <div className="video__button">
                    <Button outline onClick={handleZoomIn} disabled={zoom >= 4} title="Zoom In">
                        <Icon type="zoom-in" />
                    </Button>
                </div>
                <div className="video__button">
                    <Button outline onClick={handleZoomOut} disabled={zoom <= 1} title="Zoom Out">
                        <Icon type="zoom-out" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Video;
