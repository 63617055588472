import React from 'react';
import Button from 'src/components/Button/Button';
import Icon from 'src/components/Icon/Icon';

interface Props {
    url: string;
}

const OriginalButton: React.FC<Props> = ({ url }: Props) => (
    <div className="original-button">
        <Button blank native url={url} title={url}>
            <Icon type="external-link" />
        </Button>
    </div>
);
export default OriginalButton;
