import React, { RefObject, ReactNode } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import './Scroll.scss';

interface Props {
    children: ReactNode;
    innerRef: RefObject<HTMLDivElement>;
    style?: React.CSSProperties;
}

const Scroll: React.FC<Props> = ({ children, innerRef, style }: Props) => (
    <ScrollContainer
        className="scroll-container"
        hideScrollbars={false}
        innerRef={innerRef}
        style={style}
    >
        {children}
    </ScrollContainer>
);

export default Scroll;
