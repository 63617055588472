import React from 'react';
import { useLocation } from 'react-router-dom';
import { useCookie } from 'react-use';
import { Filter } from 'src/types/grid';
import './SaveFilterButton.scss';

interface Props {
    userFilter: Partial<Filter>;
    afterRemove: () => void;
}

const SaveFilterButton: React.FC<Props> = ({ userFilter, afterRemove }: Props) => {
    const { pathname } = useLocation();

    const [filterCookie, setFilterCookie, removeFilterCookie] = useCookie(`filter_${pathname}`);

    return !!filterCookie ? (
        <button
            type="button"
            className="save-filter save-filter--active"
            onClick={() => {
                removeFilterCookie();
                afterRemove();
            }}
        >
            Remove
        </button>
    ) : (
        <button
            type="button"
            className="save-filter"
            onClick={() => setFilterCookie(JSON.stringify(userFilter))}
        >
            Save
        </button>
    );
};

export default SaveFilterButton;
