import React, { useEffect, useState } from 'react';
import { Input, Select, Switch } from 'antd';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Filter } from 'src/types/grid';
import { useDebounce } from 'react-use';
import { Sizing } from 'src/types/common';
import { DEFAULT_FILTER } from 'src/constants';
import Button from 'src/components/Button/Button';
import { convertToValidJson, scrollPage } from 'src/utils/common';
import SaveFilterButton from 'src/components/SaveFilterButton/SaveFilterButton';
import './FilterPopup.scss';

const { Option } = Select;

interface Props {
    count: number;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    filter: Partial<Filter>;
    setFilter: React.Dispatch<React.SetStateAction<Partial<Filter>>>;
}

const FilterPopup: React.FC<Props> = ({
    count,
    isOpen,
    setIsOpen,
    filter: userFilter,
    setFilter
}: Props) => {
    const filter = {
        ...DEFAULT_FILTER,
        ...userFilter
    };

    const filterStr = JSON.stringify(filter);

    useEffect(() => {
        scrollPage(0, 0);
    }, [filterStr]);

    const ref = useDetectClickOutside({
        onTriggered: async () => {
            setIsOpen(false);
        }
    });

    const setVal = (key: keyof Filter, value: any) => {
        setFilter((prevFilter) =>
            convertToValidJson<Partial<Filter>>({
                ...prevFilter,
                [key]: value !== '' ? value : undefined
            })
        );
    };

    const [query, setQuery] = useState(typeof filter.query === 'string' ? filter.query : '');

    useDebounce(
        () => {
            setVal('query', query);
        },
        600,
        [query]
    );

    return (
        <div className={`filter-popup ${isOpen ? 'filter-popup--active' : ''}`} ref={ref}>
            <div className="filter-popup__top">
                <div className="filter-popup__title">Filters</div>
                {count > 0 && (
                    <SaveFilterButton
                        userFilter={userFilter}
                        afterRemove={() => {
                            setFilter({});
                            setQuery('');
                        }}
                    />
                )}
            </div>
            <div className="filter-popup__field">
                <Input
                    defaultValue=""
                    value={query}
                    onChange={(event) => setQuery(event?.target?.value)}
                    placeholder="Keywords"
                    size="large"
                    allowClear
                />
            </div>
            <div className="filter-popup__delimiter" />
            <div className="filter-popup__field">
                <Select
                    value={filter.imagesSize}
                    placeholder="Image Quality"
                    size="large"
                    onChange={(val) => setVal('imagesSize', val)}
                    getPopupContainer={() => ref?.current as unknown as HTMLDivElement}
                    allowClear
                    disabled={!filter.images && !filter.galleries}
                >
                    <Option value={Sizing.LARGE}>Large</Option>
                    <Option value={Sizing.MEDIUM}>Medium</Option>
                    <Option value={Sizing.SMALL}>Small</Option>
                </Select>
            </div>
            {/*<div className="filter-popup__field">*/}
            {/*    <Select*/}
            {/*        value={filter.videosSize}*/}
            {/*        placeholder="Video Quality"*/}
            {/*        size="large"*/}
            {/*        onChange={(val) => setVal('videosSize', val)}*/}
            {/*        getPopupContainer={() => ref?.current as unknown as HTMLDivElement}*/}
            {/*        allowClear*/}
            {/*        disabled={!filter.videos}*/}
            {/*    >*/}
            {/*        <Option value={Sizing.LARGE}>Large</Option>*/}
            {/*        <Option value={Sizing.MEDIUM}>Medium</Option>*/}
            {/*        <Option value={Sizing.SMALL}>Small</Option>*/}
            {/*    </Select>*/}
            {/*</div>*/}
            <div className="filter-popup__delimiter" />
            <div className="filter-popup__field">
                <div className="filter-popup__switch">
                    <Switch
                        checked={filter.images}
                        onChange={(val) => setVal('images', val)}
                        disabled={!filter.galleries && !filter.videos}
                    />
                    Show Images
                </div>
            </div>
            {/*<div className="filter-popup__field">*/}
            {/*    <div className="filter-popup__switch">*/}
            {/*        <Switch*/}
            {/*            checked={filter.videos}*/}
            {/*            onChange={(val) => setVal('videos', val)}*/}
            {/*            disabled={!filter.images && !filter.galleries}*/}
            {/*        />*/}
            {/*        Show Videos*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="filter-popup__field">
                <div className="filter-popup__switch">
                    <Switch
                        checked={filter.galleries}
                        onChange={(val) => setVal('galleries', val)}
                        disabled={!filter.images && !filter.videos}
                    />
                    Show Galleries
                </div>
            </div>
            <div className="filter-popup__delimiter" />
            <div className="filter-popup__field">
                <div className="filter-popup__switch">
                    <Switch checked={filter.nsfw} onChange={(val) => setVal('nsfw', val)} />
                    Show NSFW
                </div>
            </div>
            <div className="filter-popup__delimiter" />
            <div className="filter-popup__reset">
                <Button
                    outline
                    onClick={async () => {
                        setFilter({});
                        setQuery('');
                    }}
                >
                    Reset
                </Button>
            </div>
        </div>
    );
};

export default FilterPopup;
