import React, { ReactNode } from 'react';
import ThemeCtx from 'src/components/Context/ThemeCtx';
import ChainCtx from 'src/components/Context/ChainCtx';
import CacheCtx from 'src/components/Context/CacheCtx';
import RedditCtx from 'src/components/Context/RedditCtx';
import RedgifsCtx from 'src/components/Context/RedgifsCtx';

interface Props {
    children: ReactNode;
}

const Context = ({ children }: Props) => {
    return (
        <ThemeCtx>
            <ChainCtx>
                <CacheCtx>
                    <RedditCtx>
                        <RedgifsCtx>{children}</RedgifsCtx>
                    </RedditCtx>
                </CacheCtx>
            </ChainCtx>
        </ThemeCtx>
    );
};

export default Context;
