import { Filter, GridPost } from 'src/types/grid';
import { MediaMetadata, Post as ApiPost } from 'src/types/redditApi';
import { formatDate, getPrevSiblingsHeight } from 'src/utils/common';
import { Image, Resource, Sizing, SizingMap, Type } from 'src/types/common';
import { Node } from 'src/middlewares/reddit';
import { IMAGE_SIZING } from 'src/constants';

const MIN_WIDTH = 600;

export const prepareGridPosts = (
    rawItems: Node<ApiPost>[] | undefined,
    cache: Record<string, any>,
    callback?: (item: GridPost, image: Image) => void
) => {
    const newItems: GridPost[] = [];

    rawItems?.forEach(({ data, kind }) => {
        const { images: previewImages, reddit_video_preview } = data?.preview || {};
        const { width: imageWidth, height: imageHeight } = previewImages?.[0]?.source || {};
        // get image preview
        const [lgImage, mdImage, smImage] = (previewImages?.[0]?.resolutions || []).reverse();
        const { url: imageUrl } =
            (smImage?.width > MIN_WIDTH && smImage?.height && smImage?.url && smImage) ||
            (mdImage?.width > MIN_WIDTH && mdImage?.height && mdImage?.url && mdImage) ||
            (lgImage?.width > MIN_WIDTH && lgImage?.height && lgImage?.url && lgImage) ||
            previewImages?.[0]?.source ||
            {};
        // get video preview
        const { width: videoWidth, height: videoHeight } = reddit_video_preview || {};
        const { media_metadata: metadata = {}, gallery_data: galleryData } = data;

        let galleryWidth = 0;
        let galleryHeight = 0;
        const galleryList: MediaMetadata[] = [];

        galleryData?.items?.forEach(({ media_id }) => {
            // get gallery preview
            const [lgImage, mdImage, smImage] = (metadata?.[media_id]?.p || []).reverse();
            const galleryItemSource = metadata?.[media_id]?.s;
            const galleryItem =
                (smImage?.x > MIN_WIDTH && smImage?.y && smImage?.u && smImage) ||
                (mdImage?.x > MIN_WIDTH && mdImage?.y && mdImage?.u && mdImage) ||
                (lgImage?.x > MIN_WIDTH && lgImage?.y && lgImage?.u && lgImage) ||
                galleryItemSource;
            if (galleryItem && galleryItemSource) {
                // todo workout cases with different types of gallery items (eg image, gif, video)
                if (galleryItemSource.x > galleryWidth || galleryItemSource.y > galleryHeight) {
                    galleryWidth = galleryItemSource.x;
                    galleryHeight = galleryItemSource.y;
                }
                galleryList.push(galleryItem);
            }
        });

        const id = `${kind}_${data?.id}`;

        const newItem = {
            id: data.id,
            fullId: id,
            title: data?.title,
            author: data?.author,
            category: data?.subreddit,
            date: formatDate(data.created * 1000),
            nsfw: data?.over_18,
            url: `/media/${id}`
        };

        const isRedgifs = /redgifs\.com/i.test(data?.url);
        const isGfycat = /gfycat\.com/i.test(data?.url);
        const isImgur = imageWidth && imageHeight && /imgur\.com\/(.+)$/i.test(data?.url);
        const isGif =
            imageWidth &&
            imageHeight &&
            /(reddit\.com|redd\.it)\/(.+)\.(gifv|gif)$/i.test(data?.url);
        const isVideo = videoWidth && videoHeight && isRedgifs;
        const isImage = imageWidth && imageHeight && !(isRedgifs || isImgur || isGfycat || isGif);
        const isGallery = galleryWidth && galleryHeight;

        let type;
        let width;
        let height;
        let urls;
        let cacheImage = {} as Image;

        let resource = Resource.REDDIT;

        if (isRedgifs) {
            resource = Resource.REDGIFS;
        } else if (isGfycat) {
            resource = Resource.GFYCAT;
        } else if (isImgur) {
            resource = Resource.IMGUR;
        }

        if (isImage) {
            type = Type.IMAGE;
            width = imageWidth;
            height = imageHeight;
            urls = [imageUrl];
            cacheImage = {
                url: imageUrl,
                width: imageWidth,
                height: imageHeight
            };
        } else if (isGif) {
            type = Type.VIDEO;
            width = imageWidth;
            height = imageHeight;
            urls = [imageUrl];
            cacheImage = {
                url: imageUrl,
                width: imageWidth,
                height: imageHeight
            };
        } else if (isGfycat) {
            // console.log('gfycat', data);
        } else if (isImgur) {
            type = /.+\.(jpg|jpeg|png|webp)/i.test(data.url) ? Type.IMAGE : Type.VIDEO;
            width = imageWidth;
            height = imageHeight;
            urls = [imageUrl];
            cacheImage = {
                url: imageUrl,
                width: imageWidth,
                height: imageHeight
            };
        } else if (isVideo) {
            type = Type.VIDEO;
            width = videoWidth;
            height = videoHeight;
            urls = [imageUrl];
            cacheImage = {
                url: imageUrl,
                width: videoWidth,
                height: videoHeight
            };
        } else if (isGallery && galleryList.length > 0) {
            type = Type.GALLERY;
            width = galleryWidth;
            height = galleryHeight;
            urls = galleryList.map(({ u }) => u);
            const { u, x, y } = galleryList?.[0] || {};
            if (u && x && y) {
                cacheImage = {
                    url: u,
                    width: x,
                    height: y
                };
            }
        } else {
            // console.log(data);
        }

        const images =
            urls?.filter((url) => !!url)?.map((url) => url?.replace(/&amp;/g, '&')) || [];

        if (
            type &&
            // removing videos as REDGIFS has blocked the majority of content
            type !== Type.VIDEO &&
            width &&
            height &&
            urls &&
            urls?.length > 0 &&
            images &&
            images?.length > 0 &&
            !cache?.[id]
        ) {
            const item = {
                ...newItem,
                images,
                type,
                width,
                height,
                resource
            };
            newItems.push(item);
            callback?.(item, cacheImage);
        }
    });

    return newItems;
};

export const prepareFilteredItems = (
    items: GridPost[],
    filter: Filter,
    uniqueImages: Record<string, boolean>
) => {
    return items.filter(({ type, title, category, author, images, width, height, nsfw }) => {
        const getIsQueryMatch = () =>
            // indexOf !== -1 is the fastest approach so far
            title.indexOf(filter.query) !== -1 ||
            category.indexOf(filter.query) !== -1 ||
            author.indexOf(filter.query) !== -1;

        const getIsValidSize = (size: Sizing | null, sizing: SizingMap) => {
            const [minSize, maxSize] = size ? sizing[size] : [undefined, undefined];
            return typeof minSize === 'number' && typeof maxSize === 'number'
                ? width + height >= minSize && width + height <= maxSize
                : true;
        };

        const getIsAllowed = () => (filter.nsfw ? true : !nsfw);

        if (images.reduce((acc, image) => acc && uniqueImages[image], true)) {
            return false;
        }

        images.forEach((image) => {
            uniqueImages[image] = true;
        });

        if (type === Type.IMAGE) {
            return (
                filter.images &&
                getIsQueryMatch() &&
                getIsValidSize(filter.imagesSize, IMAGE_SIZING) &&
                getIsAllowed()
            );
            // } else if (type === Type.VIDEO) {
            //     return (
            //         filter.videos &&
            //         getIsQueryMatch() &&
            //         getIsValidSize(filter.videosSize, VIDEO_SIZING) &&
            //         getIsAllowed()
            //     );
        } else if (type === Type.GALLERY) {
            return (
                filter.galleries &&
                getIsQueryMatch() &&
                getIsValidSize(filter.imagesSize, IMAGE_SIZING) &&
                getIsAllowed()
            );
        }
        return true;
    });
};

export const getColumnsMinHeight = () => {
    const colEls = document.querySelectorAll('.grid__column');
    const colHeights: number[] = colEls.length > 0 ? Array.from(colEls, () => 0) : [0];
    colEls.forEach((colEl, index) => {
        const nodes = colEl.querySelectorAll('.post[data-id]');
        const el = nodes?.[nodes?.length - 1];
        if (el) {
            colHeights[index] = getPrevSiblingsHeight(el as HTMLElement);
        }
    });
    return Math.min(...colHeights);
};
