import React, { ReactNode } from 'react';
import useScroll from 'src/hooks/useScroll';
import Menu from 'src/components/Menu/Menu';
import './Header.scss';

interface Props {
    title: string;
    category?: string;
    left?: ReactNode;
    right?: ReactNode;
}

const Header: React.FC<Props> = ({ title, category, left, right }: Props) => {
    useScroll(() => {
        if (document.body.scrollTop > 0) {
            document.querySelector('.header__fixed')?.classList.add('header__fixed--active');
        } else {
            document.querySelector('.header__fixed')?.classList.remove('header__fixed--active');
        }
    });

    return (
        <div className="header">
            <div className="header__fixed">
                <div className="header__top">
                    <Menu />
                </div>
                <div className="header__bottom">
                    <div className="header__left">{left}</div>
                    <div className="header__center">
                        <div className="header__title">{title}</div>
                        {category && <div className="header__category">{category}</div>}
                    </div>
                    <div className="header__right">{right}</div>
                </div>
            </div>
        </div>
    );
};

export default Header;
