import { useLocation } from 'react-router-dom';
import { useCookie } from 'react-use';
import { useState } from 'react';

const useCookieFilter = <T>() => {
    const { pathname } = useLocation();
    const [filterCookie] = useCookie(`filter_${pathname}`);
    let cookieFilter;
    try {
        cookieFilter = JSON.parse(filterCookie || '');
    } catch (e) {
        // console.log(e);
    }
    return useState<T>((cookieFilter || {}) as T);
};

export default useCookieFilter;
