import React from 'react';
import './DownloadButton.scss';

interface Props {
    onClick: () => void;
}

const DownloadButton: React.FC<Props> = ({ onClick }: Props) => (
    <button type="button" onClick={onClick} className="download">
        Download
    </button>
);

export default DownloadButton;
