import { Size } from 'src/types/common';

export const scrollPage = (x: number, y: number) => document.body.scrollTo(x, y);

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const randomIntFromInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const formatDate = (date: number) =>
    new Date(date).toLocaleString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });

export const formatNumber = (num: number, digits = 1) => {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'm' },
        { value: 1e9, symbol: 'b' },
        { value: 1e12, symbol: 't' }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find((item) => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const htmlDecode = (input: string) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent || input;
};

export const getImageFitDimensions = (orig: Size, parent: Size) => {
    const { width: origWidth = 0, height: origHeight = 0 } = orig;
    const { width: parentWidth = 0, height: parentHeight = 0 } = parent;

    let fitWidth = origWidth;
    let fitHeight = origHeight;

    const origHeightIsBiggerThanParentHeight = () => {
        if (origHeight > parentHeight) {
            fitWidth = (parentHeight * origWidth) / origHeight;
            fitHeight = parentHeight;
        }
    };

    const origWidthIsBiggerThanParentWidth = () => {
        if (origWidth > parentWidth) {
            fitWidth = parentWidth;
            fitHeight = (parentWidth * origHeight) / origWidth;
        }
    };

    if (fitWidth / parentWidth > fitHeight / parentHeight) {
        origHeightIsBiggerThanParentHeight();
        origWidthIsBiggerThanParentWidth();
    } else {
        origWidthIsBiggerThanParentWidth();
        origHeightIsBiggerThanParentHeight();
    }

    return { width: fitWidth, height: fitHeight };
};

export const getPrevSiblingsHeight = (el: HTMLElement) => {
    let _el = el.previousSibling;
    let result = 0;

    while (_el) {
        result += (_el as HTMLDivElement).clientHeight;
        _el = _el.previousSibling;
    }

    return result;
};

export const convertToValidJson = <T = Record<string, any>>(obj: Record<string, any>) => {
    try {
        return JSON.parse(JSON.stringify(obj)) as T;
    } catch (e) {
        return {};
    }
};

export const findByDataId = (fullId: string) =>
    document.querySelector(`.post[data-id="${fullId}"]`) as HTMLElement | null;

export const addAndRemoveClass = (el: HTMLElement, name: string, ms = 2000) => {
    el?.classList.add(name);
    setTimeout(() => {
        el?.classList.remove(name);
    }, ms);
};
