import React, { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
    children: ReactNode;
    to: string;
}

const MenuLink: React.FC<Props> = ({ children, to }: Props) => {
    const { pathname } = useLocation();

    return (
        <Link to={to} className={`menu__link ${pathname === to ? 'menu__link--active' : ''}`}>
            {children}
        </Link>
    );
};

export default MenuLink;
