import React, { useContext } from 'react';
import { useAsync } from 'react-use';
import { Gif } from 'src/types/redgifsApi';
import NotFound from 'src/components/NotFound/NotFound';
import Video from 'src/components/Video/Video';
import { RedgifsContext } from 'src/contexts';

interface Props {
    url: string;
}

const Redgifs: React.FC<Props> = ({ url }: Props) => {
    const redgifs = useContext(RedgifsContext);

    const id = url.match(/redgifs.com\/watch\/(.+)$/)?.[1];

    const { loading: isLoading, value: data } = useAsync(
        async () => await redgifs.get<Gif>(`/${id}`)
    );

    const { urls, width, height } = data || {};

    const { hd, sd, poster, thumbnail } = urls || {};

    if (isLoading) {
        return null;
    }

    if (!(data && (hd || sd) && width && height)) {
        return <NotFound />;
    }

    return (
        <Video
            origSize={{ width, height }}
            url={(hd || sd) as string}
            poster={poster || thumbnail}
        />
    );
};

export default Redgifs;
