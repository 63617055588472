import React, { RefObject } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import Picture from 'src/components/Picture/Picture';
import { MediaMetadata } from 'src/types/redditApi';
import './Gallery.scss';

interface Props {
    list: MediaMetadata[];
    wrapperRef: RefObject<HTMLDivElement>;
}

const Gallery: React.FC<Props> = ({ list, wrapperRef }: Props) => {
    return (
        <div className="gallery">
            <Swiper
                modules={[Navigation, Pagination]}
                navigation={{
                    prevEl: `#swiperPrev`,
                    nextEl: `#swiperNext`
                }}
                pagination={{
                    el: `#swiperPagination`,
                    type: 'fraction'
                }}
                className="gallery__swiper"
                noSwiping
                noSwipingClass="swiper-slide"
            >
                {list?.map(({ u: url, x: width, y: height }) => (
                    <SwiperSlide key={url} className="gallery__slide">
                        <div className="gallery__img">
                            <Picture
                                wrapperRef={wrapperRef}
                                url={url?.replace(/&amp;/g, '&')}
                                origSize={{ width, height }}
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="gallery__top">
                <div className="gallery__pagination" id="swiperPagination" />
            </div>
            <button type="button" className="gallery__prev" id="swiperPrev" />
            <button type="button" className="gallery__next" id="swiperNext" />
            <div className="gallery__bottom"></div>
        </div>
    );
};

export default Gallery;
