import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'src/components/App/App';
import reportWebVitals from './reportWebVitals';
import VideoWc, { tagName as videoWcTagName } from 'src/wcs/Video';

import 'antd/dist/antd.min.css';
import './index.scss';

customElements.define(videoWcTagName, VideoWc);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// root.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>
// );

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
