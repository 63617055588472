import React, { useContext } from 'react';
import Header from 'src/components/Header/Header';
import PrevButton from 'src/components/PrevButton/PrevButton';
import OriginalButton from 'src/components/OriginalButton/OriginalButton';
import SearchPage from 'src/components/SearchPage/SearchPage';
import ThemeButton from 'src/components/ThemeButton/ThemeButton';
import { useTitle } from 'src/hooks/useTitle';
import { RedditContext } from 'src/contexts';

interface Props {}

const Search: React.FC<Props> = ({}: Props) => {
    const reddit = useContext(RedditContext);

    useTitle('Search');

    return (
        <div className="page">
            <Header
                title="Search"
                left={
                    <>
                        <PrevButton />
                    </>
                }
                right={
                    <>
                        <OriginalButton url={`https://reddit.com/search/`} />
                    </>
                }
            />
            <SearchPage reddit={reddit} />
            <ThemeButton />
        </div>
    );
};

export default Search;
