import React, { ReactNode } from 'react';
import './Count.scss';

interface Props {
    number?: number;
    children?: ReactNode;
}

const Count: React.FC<Props> = ({ children, number }: Props) => (
    <div className="count">
        <div className="count__content">{children}</div>
        <div className={`count__number ${number ? 'count__number--active' : ''}`}>{number}</div>
    </div>
);

export default Count;
