import React from 'react';
import Skeleton from 'src/components/Skeleton/Skeleton';

interface Props {
    width: number;
    height: number;
}

const PostSkeleton: React.FC<Props> = ({ width, height }: Props) => (
    <div className="post">
        <div className="post__inner">
            <Skeleton width={width} height={height} style={{ borderRadius: 6 }} shineWidth={400} />
        </div>
        <div className="post__title">
            <div className="post__info">
                <div className="post__category">
                    <Skeleton width={60} height={13} shineWidth={400} />
                </div>

                <div className="post__author">
                    <Skeleton width={90} height={13} shineWidth={400} />
                </div>
            </div>
            <div className="post__text">
                <Skeleton width={120} height={24} shineWidth={400} />
            </div>
        </div>
    </div>
);

export default PostSkeleton;
