import React, { useContext, useEffect } from 'react';
import Button from 'src/components/Button/Button';
import Icon from 'src/components/Icon/Icon';
import { useHotkeys } from 'react-hotkeys-hook';
import { ChainContext } from 'src/contexts';
import { useNavigate } from 'react-router-dom';
import { Location } from 'history';
import { getPrevSiblingsHeight, scrollPage } from 'src/utils/common';

interface Props {
    id?: string;
    location?: Location;
}

const MediaNavigation: React.FC<Props> = ({ id, location }: Props) => {
    const navigate = useNavigate();
    const { chain } = useContext(ChainContext);

    const left = id ? chain?.[id]?.left : undefined;
    const right = id ? chain?.[id]?.right : undefined;

    const opts = {
        state: {
            backgroundLocation: location
        },
        replace: true
    };

    const navigateLeft = () => {
        if (left) {
            navigate(`/media/${left}`, opts);
        }
    };
    const navigateRight = () => {
        if (right) {
            navigate(`/media/${right}`, opts);
        }
    };

    useEffect(() => {
        const el = document.querySelector(`.post[data-id="${id}"]`);
        if (el) {
            const sumHeight = getPrevSiblingsHeight(el as HTMLDivElement);
            scrollPage(0, sumHeight - 10);
        }
    }, [id]);

    useHotkeys('left', navigateLeft, [left]);
    useHotkeys('right', navigateRight, [right]);

    return (
        <>
            <div className={`media__nav media__nav--left ${left ? '' : 'media__nav--hidden'}`}>
                <Button outline onClick={navigateLeft} disabled={!left} title="Prev Post">
                    <Icon type="prev" />
                </Button>
            </div>
            <div className={`media__nav media__nav--right ${right ? '' : 'media__nav--hidden'}`}>
                <Button outline onClick={navigateRight} disabled={!right} title="Next Post">
                    <Icon type="next" />
                </Button>
            </div>
        </>
    );
};

export default MediaNavigation;
