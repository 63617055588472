import { useEffect } from 'react';

const useResize = (handleResize: (event: Event) => void, firstRun = false) => {
    useEffect(() => {
        if (firstRun) {
            handleResize(new Event('resize'));
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useResize;
